import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import UserForm from "../../Components/Forms/UserForm";
import { useGetUserByIdQuery, useUpdateUserMutation } from "../../redux/slice/userSlice";
import { message } from "antd";

function Update() {
  let params = useParams();
  let [updateEntityInfo, { isLoading: updating, error: updateEntityError, isSuccess: userUpdated }] = useUpdateUserMutation('updateUser')
  const { data: users, isLoading: loadingById, isSuccess: successById } = useGetUserByIdQuery(params.id)
  let data = {
    data: undefined,
    loading: updating || loadingById,
    error: false,
    dataById: successById && users,
  }

  useEffect(() => {
    updateEntityError?.message && message.error(updateEntityError?.message)
    userUpdated && message.success("User details updated successfully!")
  }, [updateEntityError?.message, userUpdated])

  return (
    <DashboardUpdate>
      <UserForm
        isUpdateForm
        {...{ params, updateEntityInfo, data }}
      />

    </DashboardUpdate>
  );
}

export default Update;
