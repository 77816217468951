import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedTeamListQuery,
  usePermanentDeleteTeamMutation,
  useRestoreTeamListMutation,
} from "../../redux/slice/team/teamApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteTeamMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreTeamListMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Member(s)",
    filterOptionTitle: "Member Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "5%" },
      { name: "Name", selector: "full_name", width: "75%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "full_name",
    searchByColumn: "full_name",
    updatePath: "/team/update",
    addPath: "/team/add",
    restorePath: "/teams",
    noDataMessage: "No Member exists",
    updateLinkColumn: "full_name",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
        .unwrap()
        .then((data) => console.log("restored", data))
        .catch(() => console.error("restore error"));
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedTeamListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
