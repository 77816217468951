import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import FaqsForm from "../../Components/Forms/FaqsForm";
import { useEffect } from "react";
import {
  useCreateFaqMutation,
  useGetFaqByIdQuery,
  useUpdateFaqInfoMutation,
} from "../../redux/slice/faq/faqApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetFaqByIdQuery;

  const [
    createEntity,
    {
      data: createdFaqData,
      isLoading: creatingFaq,
      isError,
      error: createFaqError,
    },
  ] = useCreateFaqMutation("createFaq");

  const [
    updateFaq,
    { data: updatedFaq, isLoading: updating, isError: updateFaqError, error },
  ] = useUpdateFaqInfoMutation();

  const {
    data: Faqs,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateFaqError,
    dataById: successById && Faqs,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingFaq || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <FaqsForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateFaq,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
