import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const menuApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Page", "PageById", "DetetedPage"],
  endpoints: (builder) => ({
    getPageList: createQueryForList(builder, "/menu/page/active", ["Page"]),

    getPageById: createQueryById(builder, "/menu/page/active", ["PageById"]),

    getDeletedPageList: createQueryForList(builder, "/menu/page/deleted", [
      "DetetedPage",
    ]),

    deletePageById: createDeleteRestoreMutation(
      builder,
      "/menu/page/bulk/delete",
      ["Page", "PageById"]
    ),

    permanentDeletePage: createDeleteRestoreMutation(
      builder,
      "/menu/page/bulk/perma-delete",
      ["DetetedPage"]
    ),

    restoreDeletedPage: createDeleteRestoreMutation(
      builder,
      "/menu/page/bulk/restore",
      ["DetetedPage"]
    ),

    createPage: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/menu/page/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Page"],
    }),

    updatePage: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        for (var pair of webKitFormData.entries()) {
          console.log(pair[0] + ", " + pair[1]);
        }
        return {
          url: `/menu/page/edit/${id}`,
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Page", "PageById"],
    }),
  }),
});

export const {
  useGetPageListQuery,
  useGetPageByIdQuery,
  useGetDeletedPageListQuery,
  useDeletePageByIdMutation,
  usePermanentDeletePageMutation,
  useRestoreDeletedPageMutation,
  useCreatePageMutation,
  useUpdatePageMutation,
} = menuApiSlice;
