import { useEffect } from "react"
import { message } from "antd"

const useHandleCreateMessage = (error, createSuccess, title) => {
  useEffect(() => {
    // Error popup message
    error?.data && message.error(`Error creaitng ${title}`)

    // Added popup message
    createSuccess && message.success(`${title} created successfully!`)
  }, [error, createSuccess, title])
}

export default useHandleCreateMessage
