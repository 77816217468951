import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetInquiryListQuery } from "../../redux/slice/booking/bookingApiSlice";

function InquiryList() {
  const tableConfig = {
    title: "All Inquiry",
    is_inquiry: true,
    tableColumns: [
      { name: "Name", selector: "name", width: "70%" },
      { name: "Email", selector: "email", width: "15%" },
      { name: "Date", selector: "created_at", width: "15%" },
      { name: "ID", selector: "id", width: "2%" },
      { name: "operation", selector: "id", width: "2%" },
    ],
    filterByColumn: "type",
    searchByColumn: "full_name",
    noDataMessage: "No inquiry exists",
  };
  return (
    <DashboardList
      getList={useGetInquiryListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default InquiryList;
