import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import BlogForm from "../../Components/Forms/BlogForm";
import {
  useGetBlogByIdQuery,
  useCreateBlogMutation,
} from "../../redux/slice/blog/blogApiSlice";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdBlogData,
      isLoading: creatingBlog,
      isError: createBlogError,
      isSuccess: blogCreated,
      error,
    },
  ] = useCreateBlogMutation("createBlog");

  let data = {
    data: undefined,
    loading: creatingBlog,
    error: createBlogError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingBlog,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, blogCreated, "Blog");

  return (
    <DashboardAdd>
      <BlogForm
        isAddForm
        {...{
          getEntityById: useGetBlogByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}
export default Add;
