import { apiSlice } from "../api/apiSlice";
export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllUser: builder.query({
      query: () => "/member/all",
      transformResponse: (response) => response.data,
    }),
    getDeletedUer: builder.query({
      query: () => "/member/deleted",
      transformResponse: (response) => response.data,
    }),
    getUserById: builder.query({
      query: (id) => `/member/${id}`,
      transformResponse: (response) => response.data,
    }),
    addNewUser: builder.mutation({
      query: (data) => ({
        url: "/member/add",
        method: "POST",
        body: data,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, data }) => ({
        url: `/member/edit/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteUsers: builder.mutation({
      query: (ids) => ({
        url: "/member/bulk/delete",
        method: "POST",
        body: {
          bulk: ids,
        },
      }),
    }),
  }),
});

export const {
  useGetAllUserQuery,
  useGetDeletedUerQuery,
  useGetUserByIdQuery,
  useUpdateUserMutation,
  useAddNewUserMutation,
  useDeleteUsersMutation,
} = userApiSlice;
