import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./api/apiSlice";

//AUTH SLICE
import authReducer from "./slice/auth";
import { userApiSlice } from "./slice/userSlice";

//menu Slice
import { menuApiSlice } from "./slice/menu/menuApiSlice";
import { menuTypeApiSlice } from "./slice/menu/menuTypeApiSlice";

export const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    [userApiSlice.reducerPath]: userApiSlice.reducer,
    [menuApiSlice.reducer]: menuApiSlice.reducer,
    [menuTypeApiSlice.reducer]: menuTypeApiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

setupListeners(store.dispatch);
