import UserLogPageLayout from "../Components/LogPageLayout";
import { useDispatch } from "react-redux";
import LoginForm from "../Components/Forms/LoginForm";
import { setCredentials } from "../redux/slice/auth";
import { useLoginMutation } from "../redux/slice/auth/authApiSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [signInUser, { data, isLoading, isError, isSuccess }] =
    useLoginMutation();

  const dispatch = useDispatch();

  let loginClicked = (userCredentials) => {
    signInUser(userCredentials);
  };

  if (isSuccess) {
    dispatch(setCredentials({ data }));
    navigate(from, { replace: true });
  }

  useEffect(() => {
    if (localStorage.access_token) {
      navigate(from, { replace: true });
    }
  }, [from, navigate]);

  // // utils/auth.js
  // const getAccessToken = async () => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_WETRAVEL_URL}/v1/auth/tokens/access`,
  //       {
  //         method: "POST",
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${process.env.REACT_APP_WETRAVEL_PUBLIC_API_KKEY}`,
  //         },
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to fetch access token");
  //     }

  //     const data = await response.json();
  //     console.log(data);

  //     localStorage.setItem("wetravel_access_token", data.access_token);

  //     return data;
  //   } catch (error) {
  //     console.error("Error fetching access token:", error);
  //     throw error;
  //   }
  // };

  // // utils/trip.js
  // export const createDraftTrip = async (accessToken, tripData) => {
  //   try {
  //     const response = await fetch(`${WETRAVEL_URL}/v1/draft_trips`, {
  //       method: 'POST',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Authorization': `Bearer ${accessToken}`,
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ data: tripData }),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Failed to create draft trip');
  //     }

  //     const data = await response.json();
  //     return data;
  //   } catch (error) {
  //     console.error('Error creating draft trip:', error);
  //     throw error;
  //   }
  // };

  // useEffect(() => {
  //   if (isSuccess) {
  //     getAccessToken();
  //   }
  // }, [isSuccess]);

  return (
    <UserLogPageLayout>
      <LoginForm
        loginClicked={loginClicked}
        {...{ isLoading, isError, isSuccess }}
      />
    </UserLogPageLayout>
  );
}
