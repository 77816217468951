import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetDeletedCommentListQuery } from "../../redux/slice/comment/commentApiSlice";

function DeletedList() {
  const tableConfig = {
    title: "Deleted Comments(s)",
    filterOptionTitle: "Category Type",
    filterOptions: ["category"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Order", selector: "order", width: "3%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Name", selector: "commentor_name", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "full_name",
    searchByColumn: "commentor_name",
    updatePath: "/comment/update",
    addPath: "/comment/add",
    restorePath: "/comments",
    noDataMessage: "No comments exists",
    updateLinkColumn: "full_name",
    deleteBtnText: "Permanent Delete",
    // deleteItemsByIdList: (idList) => {
    //   onDelete(idList)
    // },
    // restoreItemsByIdList: (idList) => {
    //   onRestore(idList)
    // },
  };
  return (
    <DashboardList
      getList={useGetDeletedCommentListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
