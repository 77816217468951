import React from "react";

export default function ProgressBar(props) {
  return (
    <div className="progressbar">
      <div className="item">
        <div className="progress">
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${props.percentValue}%` }}
            aria-valuenow={props.percentValue}
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <span>{props.percentValue}%</span>
          </div>
        </div>
        <div className="progress-title">
          {" "}
          <span className="text-bold">{props.fact}</span> {props.description}
        </div>
      </div>
    </div>
  );
}
