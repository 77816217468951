import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import TripinfoForm from "../../Components/Forms/TripinfoForm";

import {
  useCreateTripInfoMutation,
  useGetTripInfoByIdQuery,
  useUpdateTripInfoMutation,
} from "../../redux/slice/tripInfo/tripInfoApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetTripInfoByIdQuery;

  const [
    createEntity,
    {
      data: createdTripInfoData,
      isLoading: creatingTripInfo,
      isError,
      error: createTripInfoError,
    },
  ] = useCreateTripInfoMutation("createTripInfo");

  const [
    updateTripInfo,
    {
      data: updatedTripInfo,
      isLoading: updating,
      isError: updateTripInfoError,
      error,
    },
  ] = useUpdateTripInfoMutation("updateTripInfo");

  const {
    data: tripInfos,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  // useEffect(() => {
  //   refetch();
  // }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateTripInfoError,
    dataById: successById && tripInfos,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingTripInfo || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <TripinfoForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateTripInfo,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
