import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import MenuTypeForm from "../../Components/Forms/MenuTypeForm";
import { useParams } from "react-router-dom";
import {
  useGetMenuTypeByIdQuery,
  useCreateMenuTypeMutation,
  useUpdateMenuTypeMutation,
} from "../../redux/slice/menu/menuTypeApiSlice";

function MenuTypeUpdate() {
  let params = useParams();
  const getEntityById = useGetMenuTypeByIdQuery;

  const [
    createEntity,
    {
      data: TypecreatedMenuData,
      isLoading: creatingMenuType,
      isError,
      error: createMenuTypeError,
    },
  ] = useCreateMenuTypeMutation();

  const [
    updateEntityInfo,
    {
      data: updatedMenuTypeData,
      isLoading: updatingMenuType,
      isError: updateMenuTypeError,
      error,
    },
  ] = useUpdateMenuTypeMutation("updateMenu");

  const {
    data: dataById,
    isLoading: loadingMenuType,
    isError: updateError,
  } = getEntityById(params.id);

  let data = {
    data: undefined,
    loading: updatingMenuType || updatingMenuType || loadingMenuType,
    error: updateError || updateMenuTypeError,
    dataById,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingMenuType || updatingMenuType || loadingMenuType,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardAdd>
      <MenuTypeForm
        isUpdateForm
        {...{
          getEntityById,
          createEntity,
          updateEntityInfo,
          data,
          selectedForm,
          params,
        }}
      />
    </DashboardAdd>
  );
}

export default MenuTypeUpdate;
