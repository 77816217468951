import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetCategoryTypeListQuery,
  useDeleteCategoryTypeByIdMutation,
} from "../../redux/slice/category/categoryTypeApiSlice";

function List() {
  const [onDelete, { isLoading: deleting }] =
    useDeleteCategoryTypeByIdMutation("deleteEntityById");

  const tableConfig = {
    title: "Category types",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Category Type", selector: "category_type", width: "77%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    searchByColumn: "category_type",
    addPath: "/category-type/add",
    updatePath: "/category-type/update",
    noDataMessage: "No category type exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "category_type",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };
  return (
    <DashboardList
      getList={useGetCategoryTypeListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default List;
// const mapStateToProps = (state) => {
//   return {
//     list: state.categoryType,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getList: (tokenData) => dispatch(getList(tokenData)),
//     onDelete: (idList) => dispatch(deleteEntityById(idList)),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(List);
