import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedBlogListQuery,
  usePermanentDeleteBlogMutation,
  useRestoreBlogListMutation,
} from "../../redux/slice/blog/blogApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteBlogMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreBlogListMutation();

  let loading = permaDeleting || restoring;
  const tableConfig = {
    title: "Deleted Blogs",
    filterOptionTitle: "Blog Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Order", selector: "order", width: "5%" },
      { name: "Status", selector: "status", width: "5%" },
      { name: "Title", selector: "title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "title",
    updatePath: "/blog/update",
    addPath: "/blog/add",
    restorePath: "/blogs",
    noDataMessage: "No blog exists",
    updateLinkColumn: "title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList)
        .unwrap()
        .then((data) => console.log("restored", data))
        .catch(() => console.error("restore error"));
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedBlogListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
