import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import MenuForm from "../../Components/Forms/MenuForm";
import {
  useCreateMenuMutation,
  useGetMenuByIdQuery,
} from "../../redux/slice/menu/menuApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdMenuData,
      isLoading: creatingMenu,
      isError: createMenuError,
      isSuccess: menuCreated,
      error,
    },
  ] = useCreateMenuMutation("createMenu");

  let data = {
    data: undefined,
    loading: creatingMenu,
    error: createMenuError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingMenu,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, menuCreated, "Menu");

  return (
    <DashboardAdd>
      <MenuForm
        isAddForm
        {...{
          getEntityById: useGetMenuByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
