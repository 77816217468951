import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetCommentListQuery,
  useDeleteCommentMutation,
} from "../../redux/slice/comment/commentApiSlice";

function List() {
  const [onDelete] = useDeleteCommentMutation("deleteEntityById");
  const tableConfig = {
    title: "Comments",
    filterOptionTitle: "Category Type",
    filterOptions: ["category"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Name", selector: "commentor_name", width: "70%" },
      { name: "Status", selector: "approval_status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "commentor_name",
    searchByColumn: "commentor_name",
    updatePath: "/blog/comment/update",
    addPath: "/blog/comment/add",
    noDataMessage: "No comment exists",
    updateLinkColumn: "commentor_name",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      getList={useGetCommentListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
