import React, { useEffect, useState } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import { message } from "antd";
import { useGetCategoryListByTypeKeyQuery } from "../../redux/slice/category/categoryApiSlice";
import {
  useDeletePackageMutation,
  useGetPackageListQuery,
  useSortPackageByIdMutation,
} from "../../redux/slice/package/packageApiSlice";

function List() {
  const [filter_options, setFilterOptions] = useState();

  const [onDelete, { isLoading: deleting, isSuccess }] =
    useDeletePackageMutation("deleteEntityById");
  const [onSort, { isLoading: sorting }] =
    useSortPackageByIdMutation("sortPackageById");

  let category_type_key = "destination";
  const {
    data: categoryListByType,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useGetCategoryListByTypeKeyQuery({
    type_key: category_type_key,
  });

  const loading = deleting || sorting || categoryListLoading;

  if (categoryListError) {
    message.error("Error on loading category list");
  }
  useEffect(() => {
    if (categoryListByType) {
      setFilterOptions(
        categoryListByType?.map((itm) => {
          const { id, title, children } = itm;
          return {
            value: id,
            text: title,
            ...(children && {
              children: children?.map((child) => {
                const { id, title, children } = child;
                return {
                  value: id,
                  text: title,
                  ...(children && {
                    children: children?.map((gChild) => {
                      const { id, title } = gChild;
                      return {
                        value: id,
                        text: title,
                      };
                    }),
                  }),
                };
              }),
            }),
          };
        })
      );
    }
  }, [categoryListByType]);

  const tableConfig = {
    title: "Packages",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "package_title", width: "70%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "package_cats",
    searchByColumn: "package_title",
    updatePath: "/package/update",
    addPath: "/package/add",
    noDataMessage: "No Package exists",
    updateLinkColumn: "package_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    sortItemsByIdList: (idList) => {
      onSort(idList);
    },
    loading,
  };

  return (
    <DashboardList
      getList={useGetPackageListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
