import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InteractiveMap from "../../Common/interactive-map";
import PreviewMap from "../../Common/preview-map";
import { encode } from "@mapbox/polyline";
import axios from "axios";
import polyline from "polyline-encoded";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Input, Typography, Card, Row, Col, Collapse, Button, Popconfirm, Spin, Affix } from "antd";
import {
  ItineraryGeneralInfoForm,
  Header,
  IteneraryDaysCountForm,
  PreviewModal,
  ItineraryValues,
  mergePolylines,
  DATA_TEMPLATE,
  DEFAULT_SELECTED_DAY,
  DEFAULT_SELECTED_STOP,
  createGeoJSON,
} from "./ItineraryFormNewSupportComponents";
import { base64ToBlob } from "../../../utils/base64ToBlob";
import ModalWithMap from "./ModalWithMap";

export const DEFAULT_TRAVEL_MODE = "car";

const MapDataForm = ({
  itineraryThumb,
  setItineraryThumb,
  formFields,
  setFormFields,
  isUpdateForm,
  itinerariesDetails,
}) => {
  const [days, setDays] = useState(1);
  const [thumb, setThumb] = useState();
  const [generatingThumb, setGeneratingThumb] = useState(false);
  const [chosenItem, setChosenItem] = useState({
    day: DEFAULT_SELECTED_DAY,
    stop: DEFAULT_SELECTED_STOP,
  });
  const [previewOpen, setPreviewOpen] = useState(false);
  const [formState, setFormState] = useState("itinerary");
  const [thumbnailLoading, setThumbnailLoading] = useState(false);
  const [openMapModal, setOpenMapModal] = useState(false);
  const closeMapModal = () => setOpenMapModal(false);

  const navigate = useNavigate();
  const closeModal = () => setPreviewOpen(false);
  const handleDayChange = (e) => setDays(parseInt(e.target.value));
  const handleNavigate = () => navigate(`/itineraries`);

  function validateItineraryDetails(itineraryDetails) {
    if (!itineraryDetails || itineraryDetails.length === 0) {
      return false;
    }

    const firstItem = itineraryDetails[0];

    if (
      !firstItem.origin_coordinate ||
      !firstItem.origin_elevation ||
      !firstItem.destination_coordinate ||
      !firstItem.destination_elevation ||
      !firstItem.encoded_polyline
    ) {
      return false;
    }
    return true;
  }

  useEffect(() => {
    if (isUpdateForm) {
      const isValid = validateItineraryDetails(itinerariesDetails);

      const structuredItineraries = itinerariesDetails?.reduce((acc, itinerary) => {
        const day = itinerary.itinerary_day;
        if (!acc[day]) {
          acc[day] = {};
        }
        const stopNumber = Object.keys(acc[day]).length + 1;

        acc[day][stopNumber] = {
          ...itinerary,
          origin: isValid && itinerary.origin !== "null" ? itinerary.origin : "",
          destination: isValid && itinerary.destination !== "null" ? itinerary.destination : "",
          // origin_elevation:
          //   isValid && itinerary.origin_elevation !== "null"
          //     ? parseInt(itinerary.origin_elevation)
          //     : null,
          // destination_elevation:
          //   isValid && itinerary.destination_elevation !== "null"
          //     ? parseInt(itinerary.destination_elevation)
          //     : null,
          destination_coordinate:
            isValid && itinerary.destination_coordinate !== "null"
              ? itinerary.destination_coordinate.split(",")
              : null,
          origin_coordinate:
            isValid && itinerary.origin_coordinate !== "null"
              ? itinerary.origin_coordinate.split(",")
              : null,
          encoded_polyline:
            isValid && itinerary.encoded_polyline !== "null" ? itinerary.encoded_polyline : "",
          //duration: isValid && itinerary.encoded_polyline !== "null" ? itinerary.duration : null,
        };

        return acc;
      }, {});

      if (Object.keys(structuredItineraries).length === 0) {
        setFormFields({
          [DEFAULT_SELECTED_DAY]: { [DEFAULT_SELECTED_STOP]: DATA_TEMPLATE },
        });
        setDays(1);
      } else {
        setFormFields(structuredItineraries);
        setDays(Object.keys(structuredItineraries).length);
      }
    }
  }, [isUpdateForm, itinerariesDetails]);

  const handleDaySubmit = async (e) => {
    e.preventDefault();
    const newFormFields = {};
    for (let i = 1; i <= days; i++) {
      newFormFields[i] = { [DEFAULT_SELECTED_STOP]: DATA_TEMPLATE };
    }
    setFormFields(newFormFields);
    setFormState("itinerary");
  };

  const addDayAfter = (c_day) => {
    const newFormFields = { ...formFields };

    // Shifting days
    const keys = Object.keys(newFormFields);
    for (let i = keys.length; i >= c_day; i--) {
      newFormFields[parseInt(i) + 1] = newFormFields[i];
    }

    newFormFields[parseInt(c_day) + 1] = {
      [DEFAULT_SELECTED_STOP]: DATA_TEMPLATE,
    };

    setFormFields(newFormFields);
  };

  const addStops = (day_count) => {
    setFormFields({
      ...formFields,
      [day_count]: {
        ...formFields[day_count],
        [Object.keys(formFields[day_count]).length + 1]: DATA_TEMPLATE,
      },
    });
  };

  const handleDestinationChange = (e, index) => {
    const newFormFields = [...formFields];
    if (index < days - 1) {
      newFormFields[index + 1].origin = e.target.value;
    }
    setFormFields(newFormFields);
  };

  // const setValues = (day_count, stop_count, key, value) => {
  //   setFormFields((prevFormFields) => {
  //     const updatedFormFields = {
  //       ...prevFormFields,
  //       [day_count]: {
  //         ...prevFormFields[day_count],
  //         [stop_count]: {
  //           ...prevFormFields[day_count][stop_count],
  //           [key]: value,
  //         },
  //       },
  //     };

  //     // Update destination when origin is updated
  //     if (key === "origin" || key === "origin_coordinate" || key === "origin_elevation") {
  //       // const reverseKey = key === "origin" ? "destination" : "destination_coordinate"
  //       let reverseKey;
  //       if (key === "origin") {
  //         reverseKey = "destination";
  //       } else if (key === "origin_coordinate") {
  //         reverseKey = "destination_coordinate";
  //       } else if (key === "origin_elevation") {
  //         reverseKey = "destination_elevation";
  //       }
  //       const prevStop = stop_count - 1;
  //       const prevDay = day_count - 1;
  //       if (
  //         prevStop > 0 &&
  //         prevFormFields[day_count][prevStop] &&
  //         prevFormFields[day_count][prevStop][reverseKey] !== value
  //       ) {
  //         updatedFormFields[day_count][prevStop] = {
  //           ...prevFormFields[day_count][prevStop],
  //           [reverseKey]: value,
  //         };
  //       } else if (
  //         prevDay > 0 &&
  //         prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length] &&
  //         prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length][reverseKey] !== value
  //       ) {
  //         updatedFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length] = {
  //           ...prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length],
  //           [reverseKey]: value,
  //         };
  //       }
  //     }

  //     // Update origin when destination is updated
  //     if (
  //       key === "destination" ||
  //       key === "destination_coordinate" ||
  //       key === "destination_elevation"
  //     ) {
  //       let reverseKey;
  //       if (key === "destination") {
  //         reverseKey = "origin";
  //       } else if (key === "destination_coordinate") {
  //         reverseKey = "origin_coordinate";
  //       } else if (key === "destination_elevation") {
  //         reverseKey = "origin_elevation";
  //       }
  //       const nextStop = parseInt(stop_count) + 1;
  //       const nextDay = parseInt(day_count) + 1;
  //       const totalDays = Object.keys(prevFormFields).length;
  //       const totalStopsInDay = Object.keys(prevFormFields[day_count]).length;
  //       console.log("prevFormFields", prevFormFields);
  //       if (
  //         nextStop <= totalStopsInDay &&
  //         prevFormFields[day_count][nextStop] &&
  //         prevFormFields[day_count][nextStop][reverseKey] !== value
  //       ) {
  //         updatedFormFields[day_count][nextStop] = {
  //           ...prevFormFields[day_count][nextStop],
  //           [reverseKey]: value,
  //         };
  //       } else if (
  //         nextStop === totalStopsInDay + 1 &&
  //         day_count < totalDays &&
  //         prevFormFields[parseInt(day_count) + 1] &&
  //         prevFormFields[parseInt(day_count) + 1][1] &&
  //         prevFormFields[parseInt(day_count) + 1][1][reverseKey] !== value
  //       ) {
  //         updatedFormFields[parseInt(day_count) + 1][1] = {
  //           ...prevFormFields[parseInt(day_count) + 1][1],
  //           [reverseKey]: value,
  //         };
  //       }
  //     }
  //     return updatedFormFields;
  //   });
  // };

  const setValues = (day_count, stop_count, key, value) => {
    setFormFields((prevFormFields) => {
      const updatedFormFields = {
        ...prevFormFields,
        [day_count]: {
          ...prevFormFields[day_count],
          [stop_count]: {
            ...prevFormFields[day_count][stop_count],
            [key]: value,
          },
        },
      };

      // Update destination when origin is updated
      if (key === "origin" || key === "origin_coordinate") {
        // const reverseKey = key === "origin" ? "destination" : "destination_coordinate"
        let reverseKey;
        if (key === "origin") {
          reverseKey = "destination";
        } else if (key === "origin_coordinate") {
          reverseKey = "destination_coordinate";
        } 
        const prevStop = stop_count - 1;
        const prevDay = day_count - 1;
        if (
          prevStop > 0 &&
          prevFormFields[day_count][prevStop] &&
          prevFormFields[day_count][prevStop][reverseKey] !== value
        ) {
          updatedFormFields[day_count][prevStop] = {
            ...prevFormFields[day_count][prevStop],
            [reverseKey]: value,
          };
        } else if (
          prevDay > 0 &&
          prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length] &&
          prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length][reverseKey] !== value
        ) {
          updatedFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length] = {
            ...prevFormFields[prevDay][Object.keys(prevFormFields[prevDay]).length],
            [reverseKey]: value,
          };
        }
      }

      // Update origin when destination is updated
      if (
        key === "destination" ||
        key === "destination_coordinate" 
      ) {
        let reverseKey;
        if (key === "destination") {
          reverseKey = "origin";
        } else if (key === "destination_coordinate") {
          reverseKey = "origin_coordinate";
        }
        const nextStop = parseInt(stop_count) + 1;
        const nextDay = parseInt(day_count) + 1;
        const totalDays = Object.keys(prevFormFields).length;
        const totalStopsInDay = Object.keys(prevFormFields[day_count]).length;
        if (
          nextStop <= totalStopsInDay &&
          prevFormFields[day_count][nextStop] &&
          prevFormFields[day_count][nextStop][reverseKey] !== value
        ) {
          updatedFormFields[day_count][nextStop] = {
            ...prevFormFields[day_count][nextStop],
            [reverseKey]: value,
          };
        } else if (
          nextStop === totalStopsInDay + 1 &&
          day_count < totalDays &&
          prevFormFields[parseInt(day_count) + 1] &&
          prevFormFields[parseInt(day_count) + 1][1] &&
          prevFormFields[parseInt(day_count) + 1][1][reverseKey] !== value
        ) {
          updatedFormFields[parseInt(day_count) + 1][1] = {
            ...prevFormFields[parseInt(day_count) + 1][1],
            [reverseKey]: value,
          };
        }
      }
      return updatedFormFields;
    });
  };

  const createStraightPolyline = (startLatLng, endLatLng) => {
    const polylineCoordinates = polyline.decode(
      mergePolylines(
        Object.keys(formFields)
          .map((day) =>
            Object.keys(formFields[day]).map((stop) => formFields[day][stop].encoded_polyline)
          )
          .flat()
      )
    );
    const numIntermediatePoints = 1 + Math.ceil(Math.pow(polylineCoordinates.length, 0.8));
    const coordinates = [startLatLng];
    const latDiff =
      (parseFloat(endLatLng[0]) - parseFloat(startLatLng[0])) / (numIntermediatePoints + 1);
    const lngDiff =
      (parseFloat(endLatLng[1]) - parseFloat(startLatLng[1])) / (numIntermediatePoints + 1);

    for (let i = 0; i < numIntermediatePoints; i++) {
      const lat = parseFloat(startLatLng[0]) + latDiff * (i + 1);
      const lng = parseFloat(startLatLng[1]) + lngDiff * (i + 1);
      coordinates.push([lat, lng]);
    }

    coordinates.push(endLatLng);

    const encoded_polyline = encode(coordinates);
    return encoded_polyline;
  };

  const fetchEncodedPolyline = async (override_mode_of_transportation) => {
    const data = formFields[chosenItem.day][chosenItem.stop];
    const originLatitude = data?.origin_coordinate && data?.origin_coordinate[0];
    const originLongitude = data?.origin_coordinate && data?.origin_coordinate[1];
    const destLatitude = data?.destination_coordinate && data?.destination_coordinate[0];
    const destLongitude = data?.destination_coordinate && data?.destination_coordinate[1];

    const mode_of_transportation = override_mode_of_transportation || data.travel_mode;

    if (mode_of_transportation === "air") {
      const encoded_polyline = createStraightPolyline(
        [originLatitude, originLongitude],
        [destLatitude, destLongitude]
      );
      setValues(chosenItem.day, chosenItem.stop, "encoded_polyline", encoded_polyline);
    } else {
      if (originLatitude && originLongitude && destLongitude && destLatitude) {
        const osrmElevationUrl = `https://routing.openstreetmap.de/routed-${
          mode_of_transportation || DEFAULT_TRAVEL_MODE
        }/route/v1/driving/`;
        const lngLatOrigin = `${originLongitude},${originLatitude}`;
        const lngLatDestination = `${destLongitude},${destLatitude}`;
        const queryParams = "overview=false&alternatives=true&steps=true";
        const reqUrl = `${osrmElevationUrl}${lngLatOrigin};${lngLatDestination}?${queryParams}`;
        try {
          const response = await axios.post(reqUrl);
          if (response.status === 200) {
            const array_of_objects = response.data.routes[0].legs[0].steps;
            const duration = (response.data.routes[0].legs[0].duration / 60 / 60).toFixed(1);
            const encoded_polyline = mergePolylines(array_of_objects.map((obj) => obj.geometry));

            // Check if the last point of the polyline is near the destination
            const decodedPolyline = polyline.decode(encoded_polyline);
            const lastPoint = decodedPolyline[decodedPolyline.length - 1];
            const isConnected = isPointNearDestination(lastPoint, [destLatitude, destLongitude]);

            if (!isConnected) {
              const straightPolyline = createStraightPolyline(
                [originLatitude, originLongitude],
                [destLatitude, destLongitude]
              );
              setValues(chosenItem.day, chosenItem.stop, "encoded_polyline", straightPolyline);
            } else {
              setValues(chosenItem.day, chosenItem.stop, "encoded_polyline", encoded_polyline);
            }

            //setValues(chosenItem.day, chosenItem.stop, "duration", `${duration} hours`);
          }
        } catch (error) {
          console.error("Error fetching encoded polyline:", error);
          // Fallback to straight polyline in case of error
          const straightPolyline = createStraightPolyline(
            [originLatitude, originLongitude],
            [destLatitude, destLongitude]
          );
          setValues(chosenItem.day, chosenItem.stop, "encoded_polyline", straightPolyline);
        }
      }
    }
  };

  // Default threshold is set to 1KM
  const isPointNearDestination = (point1, point2, threshold = 1000) => {
    // Calculate distance using Haversine formula
    const R = 6371e3; // Earth's radius in meters
    const φ1 = (point1[0] * Math.PI) / 180; // latitude 1 in radians
    const φ2 = (point2[0] * Math.PI) / 180; // latitude 2 in radians
    const Δφ = ((point2[0] - point1[0]) * Math.PI) / 180; // difference in latitudes
    const Δλ = ((point2[1] - point1[1]) * Math.PI) / 180; // difference in longitudes

    const a =
      Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
      Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c; // distance in meters

    return d <= threshold;
  };

  const fetchElevation = async () => {
    const data = formFields[chosenItem.day][chosenItem.stop];
    const originLatitude = data?.origin_coordinate && data?.origin_coordinate[0];
    const originLongitude = data?.origin_coordinate && data?.origin_coordinate[1];
    const destLatitude = data?.destination_coordinate && data?.destination_coordinate[0];
    const destLongitude = data?.destination_coordinate && data?.destination_coordinate[1];
    let originElevation = null;
    let destinationElevation = null;
    if (originLatitude && originLongitude) {
      const apiUrlOrigin = `https://api.open-elevation.com/api/v1/lookup?locations=${originLatitude},${originLongitude}`;
      try {
        const response = await axios.get(apiUrlOrigin);
        if (response.status === 200) {
          const elevationData = response.data;
          const elevation = elevationData.results[0].elevation;
          originElevation = elevation;
        }
      } catch (error) {
        console.error("Error fetching elevation:", error);
      }
      //setValues(chosenItem.day, chosenItem.stop, "origin_elevation", originElevation);
    }
    if (destLatitude && destLongitude) {
      const apiUrlDestination = `https://api.open-elevation.com/api/v1/lookup?locations=${destLatitude},${destLongitude}`;
      try {
        const response = await axios.get(apiUrlDestination);
        if (response.status === 200) {
          const elevationData = response.data;
          const elevation = elevationData.results[0].elevation;
          destinationElevation = elevation;
        }
      } catch (error) {
        console.error("Error fetching elevation:", error);
      }
      //setValues(chosenItem.day, chosenItem.stop, "destination_elevation", destinationElevation);
    }
  };

  const generateRouteInfo = (override_mode_of_transportation) => {
    fetchEncodedPolyline(override_mode_of_transportation);
    fetchElevation();
  };

  const updateCoordinate = (coordinate, key) => {
    setValues(chosenItem.day, chosenItem.stop, key, coordinate);
  };

  const onSubmit = () => {
    setGeneratingThumb(true);
    setPreviewOpen(true);
  };

  const hitNetwork = async (thumbnail) => {
    const flat_itineraries = [];
    Object.keys(formFields).forEach((day, i) => {
      Object.keys(formFields[day]).forEach((stop, j) => {
        flat_itineraries.push({
          itinerary_day: day,
          itinerary_stop: stop,
          itinerary_title: formFields[day][stop].title,
          itinerary_description: formFields[day][stop].description,
          facts: Object.keys(formFields[day][stop])
            .map((item, idx) => {
              if (!(item === "title" || item === "description" || item === "facts")) {
                return {
                  fact_title: item,
                  fact_value: formFields[day][stop][item],
                };
              }
            })
            .filter((i) => i),
        });
      });
    });

    const req_obj = {
      itineraries: flat_itineraries,
      itinerary_title: undefined,
      url_title: undefined,
      slug: undefined,
      package_id: undefined,
      status: undefined,
      _method: "put",
    };

    const formData = new FormData();
    Object.keys(req_obj).forEach((key) => {
      formData.append(key, req_obj[key]);
    });
  };

  return (
    <Row className="itinerary-form">
      <Col span={16}>
        <Collapse
          style={{ backgroundColor: "white" }}
          activeKey={parseInt(chosenItem?.day - 1)}
          onChange={(v) => setChosenItem({ day: parseInt(v) + 1, stop: 1 })}
          accordion
          expandIcon={({ isActive }) =>
            !isActive ? (
              <i className="bi bi-plus-circle"></i>
            ) : (
              <i className="bi bi-dash-circle"></i>
            )
          }
        >
          {Object.keys(formFields)?.map((day_count, index) => (
            <Collapse.Panel
              key={index}
              collapsible="icon"
              header={
                <div className="panel-header">
                  <span>
                    Day {day_count}
                    {Object.keys(formFields[day_count]).length > 1 &&
                      ` (${Object.keys(formFields[day_count]).length} Stops)`}
                  </span>
                  {day_count !== "1" && (
                    <Popconfirm
                      placement="topLeft"
                      title="Are you sure you want to remove this?"
                      description={`Delete Itinerary for day ${day_count}`}
                      okText="Yes"
                      cancelText="No"
                      onCancel={() => {}}
                      onConfirm={() => {
                        const newFormFields = { ...formFields };

                        delete newFormFields[day_count];

                        const newKeys = Object.keys(newFormFields)
                          .map(Number)
                          .sort((a, b) => a - b);
                        const reindexedFormFields = {};
                        newKeys.forEach((key, index) => {
                          reindexedFormFields[(index + 1).toString()] = newFormFields[key];
                        });

                        setChosenItem({
                          day: chosenItem.day > 1 ? chosenItem.day - 1 : chosenItem.day,
                          stop: 1,
                        });

                        setFormFields(reindexedFormFields);
                        setDays(Object.keys(reindexedFormFields).length);
                      }}
                    >
                      <button className="btn btn-delete">
                        <i className="bi-trash"></i>
                      </button>
                    </Popconfirm>
                  )}
                </div>
              }
              extra={
                <Button
                  onClick={() => {
                    addDayAfter(day_count);
                  }}
                  size="small"
                >
                  add day
                </Button>
              }
            >
              <ItineraryValues
                {...{
                  setFormFields,
                  generateRouteInfo,
                  setChosenItem,
                  chosenItem,
                  setValues,
                  day_count,
                  formFields,
                  handleDestinationChange,
                  itinerariesDetails,
                }}
              />
              <br />
              {parseInt(chosenItem.day) === parseInt(day_count) && (
                <Button
                  onClick={() => {
                    addStops(parseInt(day_count));
                  }}
                  block
                >
                  <PlusOutlined />
                  Add a stop
                </Button>
              )}
            </Collapse.Panel>
          ))}
        </Collapse>
        <div>
          <br />
          <PreviewModal
            {...{ previewOpen, closeModal, formFields }}
            generatingThumb={generatingThumb}
            thumbGenerationIsComplete={(thumbnailDataUrl) => {
              // const newTab = window.open();
              // newTab.document.write('<img src="' + thumbnailDataUrl + '" alt="Screenshot"/>');
              setThumb(thumbnailDataUrl);
              setPreviewOpen(false);
              setGeneratingThumb(false);
              hitNetwork(thumbnailDataUrl);
            }}
          />
        </div>
      </Col>
      <Col span={8}>
        {formState === "itinerary" && (
          <Affix offsetTop={10}>
            <div
              style={{
                padding: 10,
                marginLeft: 10,
                backgroundColor: "white",
                borderRadius: 10,
                zIndex: 0,
              }}
            >
              {chosenItem &&
              chosenItem.day &&
              chosenItem.stop &&
              formFields &&
              Object.keys(formFields).length > 0 ? (     
                <InteractiveMap
                  zoom={2}
                  mapPosition={[27.7090302, 85.284933]}
                  data={formFields && formFields[chosenItem?.day][chosenItem?.stop]}
                  updateCoordinate={updateCoordinate}
                  generateRouteInfo={generateRouteInfo}
                  chosenItem={chosenItem}
                />
              ) : (
                <div>
                  <PreviewMap
                    mapPosition={[27.7090302, 85.284933]}
                    polylineCoordinates={polyline.decode(
                      mergePolylines(
                        Object.keys(formFields)
                          .map((day) =>
                            Object.keys(formFields[day]).map(
                              (stop) => formFields[day][stop].encoded_polyline
                            )
                          )
                          .flat()
                      )
                    )}
                    geojsonData={createGeoJSON(formFields)}
                    isPreviewMode={true}
                  />
                  <ModalWithMap
                    {...{ openMapModal, closeMapModal, formFields, thumbnailLoading }}
                    onGenerate={() => {
                      setGeneratingThumb(true);
                      setThumbnailLoading(true);
                    }}
                    generatingThumb={generatingThumb}
                    thumbGenerationIsComplete={(thumbnailDataUrl) => {
                      setItineraryThumb(thumbnailDataUrl);
                      setOpenMapModal(false);
                      setGeneratingThumb(false);
                    }}
                  />
                  <button
                    onClick={() => {
                      setChosenItem({ day: null, stop: null });
                      setOpenMapModal(true);
                    }}
                    className="btn btn-primary btn-block w-100 mt-2"
                  >
                    Generate Thumbnail
                  </button>
                </div>
              )}
            </div>
          </Affix>
        )}
      </Col>
    </Row>
  );
};
export default MapDataForm;
