import React from "react";
import { Form, Input, message } from "antd";
import { RoundedSubmitButton } from "../Core/Button";
import { BASE_URL } from "../../lib/urls";

const MediaUpdateForm = ({ item, handleSubmit }) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  if (item) {
    setFieldsValue({
      name: item.name,
      caption: item.caption,
      alt_text: item.alt_text,
    });
  }

  const onFinish = (values) => {
    handleSubmit(item.id, values)
      .then(() => {
        console.log(values);
        setFieldsValue({
          name: values.name,
          caption: values.caption,
          alt_text: values.alt_text,
        });
        message.success("Item updated successfully");
      })
      .catch(() => {
        console.error("Error handling update");
      });
  };

  return (
    <Form
      form={form}
      size="small"
      layout="vertical"
      onFinish={onFinish}
      key={item.id}
    >
      <Form.Item>
        <img src={BASE_URL + item.full_path} alt="" />
      </Form.Item>
      <Form.Item label="Caption" name="caption">
        <Input />
      </Form.Item>
      <Form.Item label="Name" name="name">
        <Input />
      </Form.Item>
      <Form.Item label="Alt Text" name="alt_text">
        <Input />
      </Form.Item>
      <Form.Item>
        <RoundedSubmitButton type="primary" htmlType="submit">
          Update
        </RoundedSubmitButton>
      </Form.Item>
    </Form>
  );
};

export default MediaUpdateForm;
