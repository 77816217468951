import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedMenuListQuery,
  usePermanentDeleteMenuMutation,
  useRestoreDeletedMenuMutation,
  useSortMenuByIdMutation,
} from "../../redux/slice/menu/menuApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteMenuMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreDeletedMenuMutation();
  const [onSort, { isLoading: sorting }] =
    useSortMenuByIdMutation("sortItemsById");

  let loading = permaDeleting || restoring || sorting;

  const tableConfig = {
    title: "Deleted Menu(s)",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "item_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "item_title",
    addPath: "/menu/add",
    updatePath: "/menu/update",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu exists",
    updateLinkColumn: "item_title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    sortItemsByIdList: (idList) => {
      onSort(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedMenuListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default DeletedList;
