import React from "react";
import Footer from "../../Components/Footer";
import NavBarDashboard from "../../Components/NavBarDashboard";
import Sidebar from "../../Components/Sidebar";
import Toolbar from "../../Components/Toolbar";

export default function DashboardLayout({ useAuth, children }) {
  const [sidebar_active, setSidebarActive] = React.useState(false);

  return (
    <div className={`contain-wrapper ${sidebar_active ? "sidebar-active" : ""}`}>
      <Toolbar
        toggleSidebar={() => setSidebarActive(!sidebar_active)}
        sidebarOpen={sidebar_active}
        useAuth={useAuth}
      />
      <Sidebar />
      <div className="page-wrapper">
        <NavBarDashboard />
        {children}
        <Footer />
      </div>
    </div>
  );
}
