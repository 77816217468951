export default function SpeedoMeter({ score, totalScore }) {
    return (
        <div className="status">
            <i className="icon">
                {((score / totalScore) * 100) >= 80 ? <svg> <use xlinkHref="/icons.svg#success" /> </svg> : score >= totalScore / 2 ? <svg><use xlinkHref="/icons.svg#warning" /> </svg> : <svg><use xlinkHref="/icons.svg#danger" /></svg>}

            </i>
            <span> {score} / {totalScore}</span>
        </div>
    )
}