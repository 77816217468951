import { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import AlbumForm from "../../Components/Forms/AlbumForm";

import {
  useCreateAlbumMutation,
  useGetAlbumByIdQuery,
  useUpdateAlbumInfoMutation,
} from "../../redux/slice/album/albumApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetAlbumByIdQuery;

  const [
    createEntity,
    {
      data: createdAlbumData,
      isLoading: creatingAlbum,
      isError,
      error: createAlbumError,
    },
  ] = useCreateAlbumMutation("createAlbum");

  const [
    updateAlbum,
    {
      data: updatedAlbum,
      isLoading: updating,
      isError: updateAlbumError,
      error,
    },
  ] = useUpdateAlbumInfoMutation("updateAlbum");

  const {
    data: albums,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateAlbumError,
    dataById: successById && albums,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingAlbum || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <AlbumForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          updateEntityInfo: updateAlbum,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
