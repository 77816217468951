import React from "react";
import { GlobalDataProvider } from "./GlobalContext";
// Import other context providers as needed

const ContextProviderWrapper = ({ children }) => {
  return (
    <GlobalDataProvider>
      {/* Wrap this children with other context providers */}
      {children}
    </GlobalDataProvider>
  );
};

export default ContextProviderWrapper;
