import DraggableDataTable from "../DraggableDataTable";
import DashboardLayout from "./DashboardLayout";
import clean from "lodash-clean";

const PageContent = ({ tableConfig, getList, dataToList, isDifferentList }) => {
  let data, loading, success, error;

  if (isDifferentList) {
    ({ data, loading, success, error } = dataToList);
  } else {
    ({
      data,
      isLoading: loading,
      isSuccess: success,
      isError: error,
    } = getList);
  }

  let status = {
    loading: loading || tableConfig.loading,
    success,
    error,
  };

  return (
    <DashboardLayout>
      <div className="page-content">
        <div className="container-fluid">
          {/* <CustomDataTable
            {...tableConfig}
            tableData={data ? data : []}
            dataToList={status}
          /> */}
          <DraggableDataTable
            {...tableConfig}
            tableData={data ? clean(data) : []}
            dataToList={status}
          />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default function DashboardList({
  tableConfig,
  getList,
  dataToList,
  isDifferentList,
}) {
  return (
    <PageContent
      getList={getList}
      tableConfig={tableConfig}
      dataToList={dataToList}
      isDifferentList={isDifferentList}
    />
  );
}
