import { apiSlice } from "../../api/apiSlice";
import {
  createDeleteRestoreMutation,
  createQueryById,
  createQueryForList,
} from "../commonFunction";

export const mediaApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Media", "MediaById", "DetetedMedia"],
  endpoints: (builder) => ({
    getMediaList: createQueryForList(builder, "/carousel/carouselcontent/active", ["Media"]),

    getMediaById: createQueryById(builder, "carouselcontent/active", ["MediaById"]),

    getDeletedMediaList: createQueryForList(builder, "/carouselcontent/deleted", ["DeletedMedia"]),

    deleteMediaById: createDeleteRestoreMutation(builder, "/carouselcontent/bulk/delete", [
      "Media",
      "MediaById",
    ]),

    permanentDeleteMedia: createDeleteRestoreMutation(
      builder,
      "/carouselcontent/bulk/perma-delete",
      ["DeletedMedia"]
    ),

    restoreDeletedMedia: createDeleteRestoreMutation(builder, "/carouselcontent/bulk/restore", [
      "DeletedMedia",
    ]),

    createMedia: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/carouselcontent/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Media"],
    }),

    updateMedia: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/carouselcontent/edit/${id}`,
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Media", "MediaById"],
    }),
  }),
});

export const {
  useGetMediaListQuery,
  useGetMediaByIdQuery,
  useGetDeletedMediaListQuery,
  useDeleteMediaByIdMutation,
  usePermanentDeleteMediaMutation,
  useRestoreDeletedMediaMutation,
  useCreateMediaMutation,
  useUpdateMediaMutation,
} = mediaApiSlice;