import { createSlice } from "@reduxjs/toolkit"

const authSlice = createSlice({
    name: 'auth',
    initialState: { user: null, token: null },
    reducers: {
        setCredentials: (state, action) => {
            const { data } = action.payload
            Object.keys(data).map((item) => {
                localStorage.setItem(item, data[item]);
            })
            state.user = data.admin_type || localStorage.getItem('admin_type')
            state.token = data.access_token || localStorage.getItem('access_token')
        },
        logOut: (state, action) => {
            localStorage.clear();
            state.user = null
            state.token = null
        }
    },
})

export const { setCredentials, logOut } = authSlice.actions

export default authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectCurrentToken = (state) => state.auth.token