import { useEffect } from "react";
import { message } from "antd";

const useHandleCreateErrorsAndSuccess = (error, createSuccess, title) => {
  useEffect(() => {
    error?.data?.errors && message.error(error.data.errors);
    createSuccess && message.success(`${title} created successfully!`);
  }, [error, createSuccess, title]);
};

export default useHandleCreateErrorsAndSuccess;
