import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import PageForm from "../../Components/Forms/PageForm";
import {
  useCreatePageMutation,
  useGetPageByIdQuery,
  useUpdatePageMutation,
} from "../../redux/slice/page/pageApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetPageByIdQuery;

  const [
    createEntity,
    {
      data: createdMenuData,
      isLoading: creatingMenu,
      isError,
      error: createMenuError,
    },
  ] = useCreatePageMutation("createMenu");

  const [
    updatePage,
    { data: updatedPage, isLoading: updating, isError: updatePageError, error },
  ] = useUpdatePageMutation("updatePage");

  const {
    data: Pages,
    isLoading: loadingById,
    isError: getEntityError,
    isSuccess: successById,
  } = getEntityById(params.id);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: getEntityError || updatePageError,
    dataById: successById && Pages,
  };
  const selectedForm = {
    data: undefined,
    loading: loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <PageForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updatePage,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}
export default Update;
