import { useEffect } from "react";
import { useParams } from "react-router-dom";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import TestimonialForm from "../../Components/Forms/TestimonialForm";
import {
  useCreateTestimonialMutation,
  useGetTestimonialByIdQuery,
  useUpdateTestimonialInfoMutation,
} from "../../redux/slice/testimonial/testimonialApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetTestimonialByIdQuery;

  const [
    createEntity,
    {
      data: createdTestimonialData,
      isLoading: creatingTestimonial,
      isError,
      error: createTestimonialError,
    },
  ] = useCreateTestimonialMutation("createTestimonial");

  const [
    updateTestimonial,
    {
      data: updatedTestimonial,
      isLoading: updating,
      isError: updateTestimonialError,
      error,
    },
  ] = useUpdateTestimonialInfoMutation("updateTestimonial");

  const {
    data: testimonials,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateTestimonialError,
    dataById: successById && testimonials,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingTestimonial || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <TestimonialForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          updateEntityInfo: updateTestimonial,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
