import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const settingsApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Settings", "SettingById"],
  endpoints: (builder) => ({
    getSettingList: createQueryForList(builder, "/global/all", ["Settings"]),

    getSettingById: createQueryById(builder, "/global/single", ["SettingById"]),

    deleteSettingById: builder.mutation({
      query: (id) => ({
        url: `/global/delete/${id}`,
        method: "POST",
        body: { _method: "delete" },
      }),
      invalidatesTags: ["Settings"],
    }),

    updateSettingById: builder.mutation({
      query: (formData) => {
        let webKitFormData = new FormData();

        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: "/global/update",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Settings", "SettingsById"],
    }),

    createSetting: builder.mutation({
      query: (formData) => {
        let webKitFormData = new FormData();
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });

        return {
          url: "/global/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Settings"],
    }),

    updateSetting: builder.mutation({
      query: ({ formData, id }) => {
        let webKitFormData = new FormData();

        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/global/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Settings"],
    }),
  }),
});
export const {
  useGetSettingListQuery,
  useGetSettingByIdQuery,
  useDeleteSettingByIdMutation,
  useUpdateSettingByIdMutation,
  useCreateSettingMutation,
  useUpdateSettingMutation,
} = settingsApiSlice;
