import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import CategoryTypeForm from "../../Components/Forms/CategoryTypeForm";
import { useParams } from "react-router";
import {
  useCreateCategoryTypeMutation,
  useGetCategoryTypeByIdQuery,
  useUpdateCategoryTypeMutation,
} from "../../redux/slice/category/categoryTypeApiSlice";

function CategoryTypeUpdate() {
  let params = useParams();
  const getEntityById = useGetCategoryTypeByIdQuery;

  const [
    createEntity,
    { data: TypecreatedCategoryData, isLoading: creatingCategoryType, isError },
  ] = useCreateCategoryTypeMutation();

  const [
    updateEntityInfo,
    {
      data: updatedCategoryTypeData,
      isLoading: updatingCategoryType,
      isError: updateCategoryTypeError,
      error,
    },
  ] = useUpdateCategoryTypeMutation("updateCategory");

  const {
    data: dataById,
    isLoading: loadingCategoryType,
    isError: updateError,
  } = getEntityById(params.id);

  let data = {
    data: undefined,
    loading:
      updatingCategoryType || updatingCategoryType || loadingCategoryType,
    error: isError || updateError || updateCategoryTypeError,
    dataById,
  };
  const selectedForm = {
    data: undefined,
    loading:
      creatingCategoryType || updatingCategoryType || loadingCategoryType,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardAdd>
      <CategoryTypeForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default CategoryTypeUpdate;
