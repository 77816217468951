import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

const GlobalContext = createContext(null);
export default GlobalContext;

export const GlobalDataProvider = ({ children }) => {
  const [globalData, setGlobalData] = useState(null);

  useEffect(() => {
    const fetchGlobalData = async () => {
      try {
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/global`);
        setGlobalData(res.data.data);
      } catch (error) {
        console.error("Failed to fetch global data:", error);
      }
    };

    if (!globalData) {
      fetchGlobalData();
    }
  }, [globalData]);

  return <GlobalContext.Provider value={globalData}>{children}</GlobalContext.Provider>;
};
