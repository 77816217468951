import React, { useEffect, useState } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteCategoryByIdMutation,
  useGetCategoryListQuery,
} from "../../redux/slice/category/categoryApiSlice";
import { useGetCategoryTypeListQuery } from "../../redux/slice/category/categoryTypeApiSlice";

function List() {
  const [filter_options, setFilterOptions] = useState();
  const {
    data: categoryTypelist,
    isLoading: categoryTypeListLoading,
    isError: categoryTypeListError,
    isSuccess: isSuccessCategoryType,
  } = useGetCategoryTypeListQuery();

  const [onDelete, { isLoading: deleteLoading, isSuccess }] =
    useDeleteCategoryByIdMutation("deleteCategoryById");

  useEffect(() => {
    if (categoryTypelist) {
      setFilterOptions(
        categoryTypelist?.map((e) => {
          return { value: e.id, text: e.category_type };
        })
      );
    }
  }, [categoryTypelist]);

  let loading = categoryTypeListLoading || deleteLoading;

  const tableConfig = {
    title: "Categories",
    filterOptionTitle: "Category Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Title", selector: "title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "title",
    addPath: "/category/add",
    updatePath: "/category/update",
    addTypePath: "/category-type/add",
    addTypeText: "Add Category Type",
    noDataMessage: "No category exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "title",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetCategoryListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default List;
