import { apiSlice } from "../../api/apiSlice";

export const categoryTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["CategoryType", "CategoryTypeById", "DetetedCategoryType"],
  endpoints: (builder) => ({
    getCategoryTypeList: builder.query({
      query: () => "/category/type/active",
      transformResponse: (response) => response.data,
      providesTags: ["CategoryType"],
    }),

    getCategoryTypeById: builder.query({
      query: (id) => `/category/type/active/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ["CategoryTypeById"],
    }),

    getDeletedCategoryTypeList: builder.query({
      query: () => "/category/type/deleted",
      transformResponse: (response) => response.data,
    }),

    deleteCategoryTypeById: builder.mutation({
      query: (id) => ({
        url: "/category/type/bulk/delete",
        method: "POST",
        body: { bulk: [id] },
      }),
      invalidatesTags: ["CategoryType", "CategoryTypeById"],
    }),

    permanentDeleteCategoryType: builder.mutation({
      query: (ids) => ({
        url: "/category/type/bulk/perma-delete",
        method: "POST",
        body: {
          bulk: ids,
        },
      }),
      invalidateTags: ["DetetedCategoryType"],
    }),

    restoreDeletedCategoryType: builder.mutation({
      query: (ids) => ({
        url: "/category/type/bulk/restore",
        method: "POST",
        body: {
          bulk: ids,
        },
        invalidateTags: ["DetetedCategoryType"],
      }),
    }),

    createCategoryType: builder.mutation({
      query: (formData) => ({
        url: "/category/type/add",
        method: "POST",
        body: formData,
      }),
      invalidateTags: ["CategoryType", "CategoryTypeById"],
    }),

    updateCategoryType: builder.mutation({
      query: ({ formData, id }) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/category/type/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidateTags: ["CategoryType", "CategoryTypeById"],
    }),

    getCategoryTypeByTypeKey: builder.query({
      query: (type_key) => `category/type/key/{type_key}${type_key}`,
      transformResponse: (response) => response.data,
    }),
  }),
});

export const {
  useGetCategoryTypeListQuery,
  useGetCategoryTypeByIdQuery,
  useGetDeletedCategoryTypeListQuery,
  useDeleteCategoryTypeByIdMutation,
  usePermanentDeleteCategoryTypeMutation,
  useRestoreDeletedCategoryTypeMutation,
  useCreateCategoryTypeMutation,
  useUpdateCategoryTypeMutation,
  useGetCategoryTypeByTypeKeyQuery,
} = categoryTypeApiSlice;
