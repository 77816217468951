import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteTestimonialMutation,
  useGetTestimonialListQuery,
} from "../../redux/slice/testimonial/testimonialApiSlice";


function List() {
  const [onDelete] = useDeleteTestimonialMutation("deleteEntityById");

  const tableConfig = {
    title: "Testimonials",
    filterOptionTitle: "Category Type",
    filterOptions: ["category"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "review_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "review_title",
    searchByColumn: "review_title",
    updatePath: "/testimonial/update",
    addPath: "/testimonial/add",
    noDataMessage: "No testimonials exists",
    updateLinkColumn: "review_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList
      getList={useGetTestimonialListQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default List;
