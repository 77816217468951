import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetTeamListQuery,
  useDeleteTeamMutation,
} from "../../redux/slice/team/teamApiSlice";

function List() {
  const [onDelete] = useDeleteTeamMutation("deleteEntityById");

  const tableConfig = {
    title: "Teams",
    filterOptionTitle: "Team Type",
    filterOptions: ["team"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Name", selector: "full_name", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "full_name",
    searchByColumn: "full_name",
    updatePath: "/team/update",
    addPath: "/team/add",
    noDataMessage: "No Member exists",
    updateLinkColumn: "full_name",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return (
    <DashboardList getList={useGetTeamListQuery()} tableConfig={tableConfig} />
  );
}

export default List;
