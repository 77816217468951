import { Alert, Col, Form, Input, Row, Spin, Switch } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const TripinfoForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const navigate = useNavigate();
  const [description, setDescription] = useState(isUpdateForm && data.dataById.description);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading;

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById.page_title,
      url_title: data.dataById.urlinfo?.url_title,
      slug: data.dataById.urlinfo?.url_slug,
      status: data.dataById.status === 1 ? true : false,
      description: data.dataById.page_description,
      meta_title: data.dataById.meta?.meta_title,
      meta_keywords: data.dataById.meta?.meta_keywords,
      meta_description: data.dataById.meta?.meta_description,
    });
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      let cleanedValues = cleanFormValues(values);

      if (action === "add") {
        createEntity({
          ...cleanedValues,
          status: cleanedValues.status ? 1 : 0,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...cleanedValues,
          status: cleanedValues.status ? 1 : 0,
        })
          .unwrap()
          .then(() => navigate("/tripinfos"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...cleanedValues,
            status: cleanedValues.status ? 1 : 0,
          },
          id: params.id,
        })
          .unwrap()
          .then(() => {
            // // if we dont want refetch !
            // setFieldsValue({
            //   ...cleanedValues,
            //   status: cleanedValues.status ? 1 : 0,
            // });
          })
          .catch();
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Note",
    titleToUpdate: "Update Note",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Note",
    taskCompleteAddMessage: "Note is added!",
    taskCompleteUpdateMessage: "Note is updated!",
    discardLink: "tripinfos",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Info title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Info title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        url_title: e.target.value,
                        meta_title: e.target.value,
                        slug: slugify(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item
                  label="Url Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input onChange={(e) => form.setFieldsValue({ slug: slugify(e.target.value) })} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8} hidden>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                  </Form.Item>
                </div>
              </Col>

              <Col className="gutter-row" xs={24}>
                <Form.Item label="Description" name="description">
                  <TextEditor
                    value={description}
                    initialValue={isUpdateForm && description}
                    onEditorChange={(e) => {
                      setDescription(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8} hidden>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item className="mb-0" label="Meta Description" name="meta_description">
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default TripinfoForm;
