import React, { useState } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import { useDeleteUsersMutation, useGetAllUserQuery } from "../../redux/slice/userSlice";

function List() {
  const [ onDelete, {isLoading: deleteLoading, isSuccess} ] = useDeleteUsersMutation('deleteEntityById')

  const [filter_options, setFilterOptions] = useState([
    { value: "admin", text: "Admin" },
    { value: "super", text: "Super Admin" },
    { value: "member", text: "Member" }
  ])
  const tableConfig = {
    title: "Users",
    filterOptionTitle: "User Type",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Name", selector: 'name', width: '70%' },
      { name: "Updated At", selector: 'updated_at', width: '15%' },
      { name: "ID", selector: 'id', width: '2%' }
    ],
    filterByColumn: 'type',
    searchByColumn: 'name',
    addPath: '/user/add',  
    updatePath: "/user/update",
    noDataMessage: "No user exists",
    deleteBtnText: 'Delete',
    updateLinkColumn: 'name',
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  }

  return (
    <DashboardList
      getList={useGetAllUserQuery()}
      tableConfig={tableConfig}
    />
  );
}
export default List