import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import CommentForm from "../../Components/Forms/CommentForm";
import {
  useCreateCommentMutation,
  useGetCommentByIdQuery,
  useUpdateCommentInfoMutation,
} from "../../redux/slice/comment/commentApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetCommentByIdQuery;

  const [
    createEntity,
    {
      data: createdCommentData,
      isLoading: creatingComment,
      isError,
      error: createCommentError,
    },
  ] = useCreateCommentMutation("createComment");

  const [
    updateComment,
    {
      data: updatedComment,
      isLoading: updating,
      isError: updateCommentError,
      error,
    },
  ] = useUpdateCommentInfoMutation("updateComment");

  const {
    data: blogs,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateCommentError,
    dataById: successById && blogs,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingComment || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardUpdate>
      <CommentForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateComment,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}
export default Update;
