import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const blogApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Blog", "BlogById", "DeletedBlog"],
  endpoints: (builder) => ({
    getBlogList: createQueryForList(builder, "/blog/active", ["Blog"]),

    getBlogById: createQueryById(builder, "/blog", ["BlogById"]),

    getDeletedBlogList: createQueryForList(builder, "/blog/deleted", [
      "DeletedBlog",
    ]),

    deleteBlog: createDeleteRestoreMutation(builder, "/blog/bulk/delete", [
      "DeletedBlog", "Blog"
    ]),

    permanentDeleteBlog: createDeleteRestoreMutation(
      builder,
      "/blog/bulk/perma-delete",
      ["DeletedBlog"]
    ),

    restoreBlogList: createDeleteRestoreMutation(
      builder,
      "/blog/bulk/restore",
      ["Blog", "DeletedBlog"]
    ),

    createBlog: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append(`categories[${i}]`, data.categories[i]);
          }
        }
        if (data.authors) {
          for (let i = 0; i < data.authors.length; i++) {
            webKitFormData.append(`authors[${i}]`, data.authors[i]);
          }
        }
        if (data.tags) {
          for (let i = 0; i < data.tags.length; i++) {
            webKitFormData.append(`tags[${i}]`, data.tags[i]);
          }
        }

        ["authors", "tags", "categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/blog/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      transformResponse: (response) => response.data,
      invalidatesTags: ["Blog"],
    }),

    updateBlogInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        if (data.categories) {
          for (let i = 0; i < data.categories.length; i++) {
            webKitFormData.append(`categories[${i}]`, data.categories[i]);
          }
        }
        if (data.authors) {
          for (let i = 0; i < data.authors.length; i++) {
            webKitFormData.append(`authors[${i}]`, data.authors[i]);
          }
        }
        if (data.tags) {
          for (let i = 0; i < data.tags.length; i++) {
            webKitFormData.append(`tags[${i}]`, data.tags[i]);
          }
        }
        ["authors", "tags", "categories"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/blog/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Blog", "BlogById"],
    }),
    checkSlug: builder.mutation({
      query: (slug) => ({
        url: "/blog/slug/check",
        method: "POST",
        body: { slug },
      }),
    }),
  }),
});
export const {
  useGetBlogListQuery,
  useGetBlogByIdQuery,
  useGetDeletedBlogListQuery,
  useDeleteBlogMutation,
  usePermanentDeleteBlogMutation,
  useRestoreBlogListMutation,
  useCreateBlogMutation,
  useUpdateBlogInfoMutation,
  useCheckSlugMutation,
} = blogApiSlice;
