import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const tripInfoApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["TripInfo", "TripInfoById", "DetetedTripInfo"],
  endpoints: (builder) => ({
    getTripInfoList: createQueryForList(builder, "/travel/tripinfo/active", [
      "TripInfo",
    ]),

    getTripInfoById: createQueryById(builder, "/travel/tripinfo/active", [
      "TripInfoById",
    ]),

    getDeletedTripInfoList: createQueryForList(
      builder,
      "/travel/tripinfo/deleted",
      ["DeletedTripInfo"]
    ),

    deleteTripInfoById: createDeleteRestoreMutation(
      builder,
      "/travel/tripinfo/bulk/delete",
      ["TripInfo", "TripInfoById", "DeletedTripInfo"]
    ),

    permanentDeleteTripInfo: createDeleteRestoreMutation(
      builder,
      "/travel/tripinfo/bulk/perma-delete",
      ["DeletedTripInfo"]
    ),

    restoreDeletedTripInfo: createDeleteRestoreMutation(
      builder,
      "/travel/tripinfo/bulk/restore",
      ["DeletedTripInfo"]
    ),

    createTripInfo: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        return {
          url: "/travel/tripinfo/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["TripInfo"],
    }),

    updateTripInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/travel/tripinfo/edit/${id}`,
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["TripInfo", "TripInfoById"],
    }),
  }),
});

export const {
  useGetTripInfoListQuery,
  useGetTripInfoByIdQuery,
  useGetDeletedTripInfoListQuery,
  useDeleteTripInfoByIdMutation,
  usePermanentDeleteTripInfoMutation,
  useRestoreDeletedTripInfoMutation,
  useCreateTripInfoMutation,
  useUpdateTripInfoMutation,
} = tripInfoApiSlice;
