import { message } from "antd";
import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeletePageByIdMutation,
  useGetPageListQuery,
} from "../../redux/slice/page/pageApiSlice";

function List() {
  const [onDelete, { isLoading: deleteLoading, isSuccess }] =
    useDeletePageByIdMutation("deleteEntityById");

  let loading = deleteLoading;

  const tableConfig = {
    title: "Articles",
    filterOptionTitle: "Team Type",
    filterOptions: ["package"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "page_title", width: "77%" },
      { name: "Status", selector: "publish_status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "page_title",
    updatePath: "/article/update",
    addPath: "/article/add",
    noDataMessage: "No Article exists",
    updateLinkColumn: "page_title",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    loading,
  };

  return (
    <DashboardList getList={useGetPageListQuery()} tableConfig={tableConfig} />
  );
}

export default List;
