import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const albumApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Album", "AlbumById", "DeletedAlbum"],
  endpoints: (builder) => ({
    getAlbumList: createQueryForList(builder, "/carousel/active", ["Album"]),

    getAlbumById: createQueryById(builder, "/carousel/active", ["AlbumById"]),

    getDeletedAlbumList: createQueryForList(builder, "/carousel/deleted", [
      "DeletedAlbum",
    ]),

    deleteAlbum: createDeleteRestoreMutation(builder, "/carousel/bulk/delete", [
      "DeletedAlbum", "Album",
    ]),

    permanentDeleteAlbum: createDeleteRestoreMutation(
      builder,
      "/carousel/bulk/perma-delete",
      ["DeletedAlbum"]
    ),

    restoreAlbumList: createDeleteRestoreMutation(
      builder,
      "/carousel/bulk/restore",
      ["Album", "DeletedAlbum"]
    ),

    createAlbum: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );

        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });

        return {
          url: "/carousel/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Album"],
    }),

    updateAlbumInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/carousel/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Album", "AlbumById"],
    }),
  }),
});
export const {
  useGetAlbumListQuery,
  useGetAlbumByIdQuery,
  useGetDeletedAlbumListQuery,
  useDeleteAlbumMutation,
  usePermanentDeleteAlbumMutation,
  useRestoreAlbumListMutation,
  useCreateAlbumMutation,
  useUpdateAlbumInfoMutation,
} = albumApiSlice;
