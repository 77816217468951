import { Col, Form, Input, InputNumber, Row, Spin, Switch } from "antd";
import React from "react";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const RedirectionForm = ({ updateEntityInfo, isAddForm, isUpdateForm, data }) => {
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = data.loading;

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      let cleanedValues = cleanFormValues(values);

      if (action === "update") {
        updateEntityInfo({ ...cleanedValues });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      redirects: data?.data,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add User",
    titleToUpdate: "Redirection",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Save",
    taskCompleteAddMessage: "Redirection is added!",
    taskCompleteUpdateMessage: "Redirection is updated!",
    discardLink: "./",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        <Spin spinning={loading} delay={500}>
          <div className="common-module group-price bg-white departures">
            <h4>Ex. Redirection</h4>
            <div className=" text-muted">
              <p>
                Source:{" "}
                <input
                  type="text"
                  placeholder="/your-source-url"
                  className="form-control"
                  readOnly
                  style={{
                    width: "180px",
                    height: "32px",
                    display: "inline-block",
                    minHeight: "32px",
                  }}
                />{" "}
                URL excluding base domain
              </p>
              <p>
                Destination:{" "}
                <input
                  type="text"
                  placeholder="/your-destination-url"
                  className="form-control"
                  readOnly
                  style={{
                    width: "180px",
                    height: "32px",
                    display: "inline-block",
                    minHeight: "32px",
                  }}
                />{" "}
                URL excluding base domain
              </p>
            </div>
            <hr />
            <Form.List name="redirects">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      <div className="ant-form-item-label">
                        <label>Redirection #{index + 1}</label>{" "}
                        <span className="btn-delate" onClick={() => remove(field.name)}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>{" "}
                          Delete
                        </span>
                      </div>

                      <Row gutter={{ xs: 5, sm: 5, md: 5 }} key={field.key} align="baseline">
                        <Col md={6} xs={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "source"]}
                            rules={[
                              {
                                required: true,
                                message: "source is required",
                              },
                            ]}
                          >
                            <Input min={1} placeholder="source*" />
                          </Form.Item>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Item
                            {...field}
                            name={[field.name, "destination"]}
                            rules={[
                              {
                                required: true,
                                message: "destination is required",
                              },
                            ]}
                          >
                            <Input placeholder="Destination*" />
                          </Form.Item>

                          {isUpdateForm && (
                            <Form.Item hidden {...field} initialValue={0} name={[field.name, "id"]}>
                              <InputNumber />
                            </Form.Item>
                          )}
                        </Col>

                        <Col md={6} xs={12}>
                          <div className="switch-group">
                            <Form.Item
                              valuePropName="checked"
                              {...field}
                              name={[field.name, "is_permanent"]}
                            >
                              <Switch
                                checkedChildren="Permanent"
                                unCheckedChildren="Not Permanent"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col md={6} xs={12} hidden>
                          <div className="switch-group">
                            <Form.Item
                              valuePropName="checked"
                              {...field}
                              name={[field.name, "redirect_status"]}
                            >
                              <Switch
                                checkedChildren="Default Price"
                                unCheckedChildren="Not Default Price"
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                    </>
                  ))}
                  <Col>
                    <Form.Item>
                      <button
                        type="button"
                        onClick={() => add()}
                        className="btn btn-outline-secondary rounded-pill font-weight-semibold"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="currentColor"
                          className="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add New
                      </button>
                    </Form.Item>
                  </Col>
                </>
              )}
            </Form.List>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default RedirectionForm;
