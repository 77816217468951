import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const redirectionApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Redirection"],
  endpoints: (builder) => ({
    getRedirectionList: createQueryForList(builder, "/redirectmanager/all", [
      "Redirection",
    ]),

    deleteRedirection: createDeleteRestoreMutation(
      builder,
      "/redirectmanager/bulk/delete",
      ["Redirection"]
    ),

    updateRedirectionInfo: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        );
        let webKitFormData = new FormData();

        if (data.redirects) {
          let source = data.redirects.map((item) => item.source);
          let destination = data.redirects.map((item) => item.destination);
          let is_permanent = data.redirects.map((item) => item.is_permanent);
          let redirect_status = data.redirects.map(
            (item) => item.redirect_status
          );
          let ID = data.redirects.map((item) => item.id);
          for (let i = 0; i < source.length; i++) {
            webKitFormData.append("redirects[" + i + "][source]", source[i]);
          }
          for (let i = 0; i < destination.length; i++) {
            webKitFormData.append(
              "redirects[" + i + "][destination]",
              destination[i]
            );
          }
          for (let i = 0; i < ID.length; i++) {
            webKitFormData.append("redirects[" + i + "][id]", ID[i]);
          }
          for (let i = 0; i < is_permanent.length; i++) {
            webKitFormData.append(
              "redirects[" + i + "][is_permanent]",
              is_permanent[i] === true ? 1 : 0
            );
          }
          for (let i = 0; i < redirect_status.length; i++) {
            webKitFormData.append(
              "redirects[" + i + "][redirect_status]",
              redirect_status[i] === true ? 1 : 0
            );
          }
        }

        ["redirects"].forEach((e) => delete data[e]);

        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: "/redirectmanager/edit",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Redirection"],
    }),
  }),
});
export const {
  useGetRedirectionListQuery,
  useUpdateRedirectionInfoMutation,
  useDeleteRedirectionMutation,
} = redirectionApiSlice;
