import { apiSlice } from "../../api/apiSlice";
import { createQueryForList, createQueryById } from "../commonFunction";

export const bookingApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Booking", "BookingById", "DeletedBooking"],
  endpoints: (builder) => ({
    getBookingList: createQueryForList(builder, "/front/packagebooking/all", [
      "Booking",
    ]),

    getBookingById: createQueryById(builder, "/front/packagebooking/single", [
      "BookingById",
    ]),

    sendEmail: builder.mutation({
      query: (id) => ({
        url: `/front/packagebooking/sendemail/${id}`,
        method: "POST",
        body: {},
      }),
    }),

    getInvoice: createQueryById(builder, "/front/bookinginvoice/single"),

    getInquiryList: createQueryForList(builder, "/front/contactmessage/all"),

    createBooking: builder.mutation({
      query: (formData) => {
        let webKitFormData = new FormData();
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });

        return {
          url: "/front/packagebooking/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Booking"],
    }),

    updateBookingInfo: builder.mutation({
      query: ({ formData, id }) => {
        let webKitFormData = new FormData();
        Object.keys(formData).forEach((item, i) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/booking/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Booking", "BookingById"],
    }),
  }),
});
export const {
  useGetBookingListQuery,
  useGetBookingByIdQuery,
  useSendEmailMutation,
  useGetInvoiceQuery,
  useGetInquiryListQuery,
  useCreateBookingMutation,
  useUpdateBookingInfoMutation,
} = bookingApiSlice;
