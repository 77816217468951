import { message } from "antd"
import { apiSlice } from "../../api/apiSlice"

export const itineraryApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Itinerary",
    "ItineraryById",
    "ItineraryByPackageId",
    "DeletedItinerary",
    "PackageById",
  ],
  endpoints: (builder) => ({
    getItineraryList: builder.query({
      query: () => "/travel/itinerary/active",
      transformResponse: (response) => response.data,
      providesTags: ["Itinerary"],
    }),
    getItineraryById: builder.query({
      query: (id) => `/travel/itinerary/active/${id}`,
      transformResponse: (response) => response.data,
      providesTags: ["ItineraryById"],
    }),
    getItineraryByPackageId: builder.query({
      query: (id) => `/travel/itinerary/package/${id}`,
      transformResponse: (response) => {
        if (response.error) {
          throw new Error(response.error.message)
        }
        return response.data
      },
      providesTags: ["ItineraryByPackageId"],
    }),
    getDeletedItineraryList: builder.query({
      query: () => "/travel/itinerary/deleted",
      transformResponse: (response) => response.data,
      providesTags: ["DeletedItinerary"],
    }),
    deleteItinerary: builder.mutation({
      query: (idList) => ({
        url: "/travel/itinerary/bulk/delete",
        method: "POST",
        body: { bulk: idList },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Itinerary", "DeletedItinerary"],
    }),
    permanentDeleteItinerary: builder.mutation({
      query: (idList) => ({
        url: "/travel/itinerary/bulk/perma-delete",
        method: "POST",
        body: { bulk: idList },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["DeletedItinerary"],
    }),
    restoreItineraryList: builder.mutation({
      query: (idList) => ({
        url: "/travel/itinerary/bulk/restore",
        method: "POST",
        body: { bulk: idList },
      }),
      transformResponse: (response) => response.data,
      invalidatesTags: ["Itinerary", "DeletedItinerary"],
    }),

    createItinerary: builder.mutation({
      query: (formData) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        )
        let webKitFormData = new FormData()

        if (data?.itineraries) {
          console.log(data?.itineraries)
          let count = 0
          const appendItineraryData = (key, val) => {
            webKitFormData.append(`itineraries[${count}][${key}]`, val)
          }
          Object.entries(data.itineraries).forEach(([dayKey, stops], daycounter) => {
            Object.entries(stops).forEach(([stopKey, value], stopcounter) => {
              appendItineraryData("itinerary_day", daycounter + 1)
              appendItineraryData("itinerary_stop", stopcounter + 1)
              appendItineraryData("itinerary_title", value.itinerary_title)
              appendItineraryData("itinerary_description", value.itinerary_description)
              appendItineraryData("accommodation", value.accommodation)
              appendItineraryData("meals", value.meals)
              appendItineraryData("origin", value.origin)
              appendItineraryData("destination", value.destination)
              appendItineraryData("travel_mode", value.travel_mode)
              appendItineraryData("distance", value.distance)
              appendItineraryData("duration", value.duration)
              appendItineraryData("destination_coordinate", value.destination_coordinate)
              appendItineraryData("origin_coordinate", value.origin_coordinate)
              appendItineraryData("origin_elevation", value.origin_elevation)
              appendItineraryData("destination_elevation", value.destination_elevation)
              appendItineraryData("encoded_polyline", value.encoded_polyline)

              if (value.gallery_media) {
                for (let i = 0; i < value.gallery_media?.length; i++) {
                  webKitFormData.append(
                    `itineraries[${count}][gallery_media][${i}]`,
                    value.gallery_media[i]
                  )
                }
              }
              count++
            })
          })
        }

        ;["itineraries"].forEach((e) => delete data[e])
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item])
        })

        return {
          url: "/travel/itinerary/add",
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        }
      },
      invalidatesTags: ["ItineraryByPackageId", "PackageById"],
    }),

    updateItineraryInfo: builder.mutation({
      query: ({ formData, id }) => {
        let data = Object.fromEntries(
          Object.entries(formData)
            .filter(([_, v]) => v != null)
            .filter(([_, v]) => v !== "")
            .filter(([_, v]) => v !== undefined)
        )

        let webKitFormData = new FormData()

        if (data?.itineraries) {
          let count = 0
          const appendItineraryData = (key, val) => {
            webKitFormData.append(`itineraries[${count}][${key}]`, val)
          }
          Object.entries(data.itineraries).forEach(([dayKey, stops], daycounter) => {
            Object.entries(stops).forEach(([stopKey, value], stopcounter) => {
              appendItineraryData("id", value.id !== null && value.id !== undefined ? value.id : 0)
              appendItineraryData("itinerary_day", daycounter + 1)
              appendItineraryData("itinerary_stop", stopcounter + 1)
              appendItineraryData("origin", value.origin)
              appendItineraryData("destination", value.destination)
              appendItineraryData("origin_elevation", value.origin_elevation)
              appendItineraryData("destination_elevation", value.destination_elevation)
              appendItineraryData("travel_mode", value.travel_mode)
              appendItineraryData("encoded_polyline", value.encoded_polyline)

              appendItineraryData("destination_coordinate", value.destination_coordinate)
              appendItineraryData("origin_coordinate", value.origin_coordinate)
              appendItineraryData("duration", value.duration)
              appendItineraryData("accommodation", value.accommodation)
              appendItineraryData("meals", value.meals)
              appendItineraryData("itinerary_title", value.itinerary_title)
              appendItineraryData("itinerary_description", value.itinerary_description)
              appendItineraryData("distance", value.distance)

              if (value.gallery?.length) {
                for (let i = 0; i < value.gallery.length; i++) {
                  webKitFormData.append(
                    `itineraries[${count}][gallery_media][${i}]`,
                    value.gallery[i].id
                  )
                }
              }
              count++
            })
          })
        }

        ;["itineraries"].forEach((e) => delete data[e])
        Object.keys(data).forEach((item, i) => {
          webKitFormData.append(item, data[item])
        })
        webKitFormData.append("_method", "put")
        return {
          url: `/travel/itinerary/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        }
      },
      async onQueryStarted({ formData }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          itineraryApiSlice.util.updateQueryData(
            "getItineraryByPackageId",
            parseInt(formData.package_id),
            (draft) => {
              Object.assign(draft, {
                ...formData,
                urlinfo: {
                  url_slug: formData.slug,
                  url_title: formData.url_title,
                },
              })
            }
          )
        )

        try {
          await queryFulfilled
        } catch (error) {
          patchResult.undo()
          console.error("Error on updating the data:", error)
          message.error("Error on updating the data")
        }
      },
      invalidatesTags: ["ItineraryByPackageId"],
    }),
  }),
})
export const {
  useGetItineraryListQuery,
  useGetItineraryByIdQuery,
  useGetItineraryByPackageIdQuery,
  useGetDeletedItineraryListQuery,
  useDeleteItineraryMutation,
  usePermanentDeleteItineraryMutation,
  useRestoreItineraryListMutation,
  useCreateItineraryMutation,
  useUpdateItineraryInfoMutation,
} = itineraryApiSlice
