import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedPageListQuery,
  usePermanentDeletePageMutation,
  useRestoreDeletedPageMutation,
} from "../../redux/slice/page/pageApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeletePageMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreDeletedPageMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Article(s)",
    filterOptionTitle: "Category Type",
    filterOptions: ["category"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Order", selector: "order", width: "3%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "page_title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "title",
    searchByColumn: "page_title",
    updatePath: "/article/update",
    addPath: "/article/add",
    restorePath: "/articles",
    noDataMessage: "No article exists",
    updateLinkColumn: "page_title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedPageListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
