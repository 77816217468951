import "@popperjs/core";
import "bootstrap";
import React from "react";
import ReactDOM from "react-dom/client";

import "antd/dist/antd.css";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "./App";
import "./assets/bootstrap-icons/font/bootstrap-icons.css";
import "./assets/scss/main.scss";
import ErrorBoundary from "./Components/ErrorBoundary";
import "./index.css";
import { store } from "./redux/store";
import ContextProviderWrapper from "./utils/ContextProviderWrapper";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ContextProviderWrapper>
        <BrowserRouter>
          <Routes>
            <Route
              path="/*"
              element={
                <ErrorBoundary>
                  <App />
                </ErrorBoundary>
              }
            />
          </Routes>
        </BrowserRouter>
      </ContextProviderWrapper>
    </Provider>
  </React.StrictMode>
);
