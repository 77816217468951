import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedMenuTypeListQuery,
  usePermanentDeleteMenuTypeMutation,
  useRestoreDeletedMenuTypeMutation,
} from "../../redux/slice/menu/menuTypeApiSlice";

function DeletedTypeList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteMenuTypeMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreDeletedMenuTypeMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Menu Type(s)",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "type_title", width: "77%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "type_title",
    addPath: "/menu-type/add",
    updatePath: "/menu/update",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu type exists",
    updateLinkColumn: "type_title",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedMenuTypeListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedTypeList;
