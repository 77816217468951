import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedAlbumListQuery,
  usePermanentDeleteAlbumMutation,
  useRestoreAlbumListMutation,
} from "../../redux/slice/album/albumApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteAlbumMutation();

  const [onRestore, { isLoading: restoring }] = useRestoreAlbumListMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Albumn(s)",
    filterOptionTitle: "Category Type",
    filterOptions: ["category"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      // { name: "Order", selector: "order", width: "3%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "title", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "name",
    searchByColumn: "name",
    updatePath: "/album/update",
    addPath: "/album/add",
    restorePath: "/albums",
    noDataMessage: "No Album exists",
    updateLinkColumn: "name",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedAlbumListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
