import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
  createQueryById,
  createDeleteRestoreMutation,
} from "../commonFunction";

export const categoryApiSlice = apiSlice.injectEndpoints({
  tagTypes: [
    "Category",
    "CategoryById",
    "DetetedCategory",
    "CategoryByTypeKey",
  ],
  endpoints: (builder) => ({
    getCategoryList: createQueryForList(builder, "/category/active", [
      "Category",
    ]),

    getCategoryListByTypeKey: builder.query({
      query: ({ type_key }) => `category/type/key/${type_key}`,
      transformResponse: (response) => response.data,
      providesTags: ["CategoryByTypeKey"],
    }),

    getCategoryOptions: createQueryForList(builder, "/category/options", [
      "Category",
    ]),

    getCategoryById: createQueryById(builder, "/category", ["CategoryById"]),

    getDeletedCategoryList: createQueryForList(builder, "/category/deleted", [
      "DeletedCategory",
    ]),

    deleteCategoryById: createDeleteRestoreMutation(
      builder,
      "/category/bulk/delete",
      ["Category", "CategoryById"]
    ),

    permanentDeleteCategory: createDeleteRestoreMutation(
      builder,
      "/category/bulk/perma-delete",
      ["DeletedCategory", "Category"]
    ),

    restoreDeletedCategory: createDeleteRestoreMutation(
      builder,
      "/category/bulk/restore",
      ["DeletedCategory", "Category"]
    ),

    createCategory: builder.mutation({
      query: (formData) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });

        return {
          url: "/category/add",
          method: "POST",
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Category"],
    }),

    updateCategory: builder.mutation({
      query: ({ formData, id }) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/category/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: webKitFormData,
        };
      },
      invalidatesTags: ["Category", "CategoryById"],
    }),
  }),
});

export const {
  useGetCategoryListQuery,
  useGetCategoryListByTypeKeyQuery,
  useGetCategoryOptionsQuery,
  useGetCategoryByIdQuery,
  useGetDeletedCategoryListQuery,
  useDeleteCategoryByIdMutation,
  usePermanentDeleteCategoryMutation,
  useRestoreDeletedCategoryMutation,
  useCreateCategoryMutation,
  useUpdateCategoryMutation,
} = categoryApiSlice;
