import React, { useState, useEffect } from "react"
import { Button, Modal, Menu, Spin, message, Pagination, Popconfirm, Empty, Input } from "antd"
import { CloudUploadOutlined, FolderOpenOutlined, InboxOutlined } from "@ant-design/icons"
import { BASE_URL } from "../../lib/urls"
import {
  useAddMediaMutation,
  useBulkDeleteMediaMutation,
  useEditMediaMutation,
  useFetchMediaDirectoryListQuery,
  useGetMediaByDirectoryQuery,
} from "../../redux/slice/mediaApiSlice"
import MediaUpdateForm from "./MediaUpdateForm"
import slugify from "react-slugify"

const { Search } = Input

function unslugify(slug) {
  const withoutExtension = slug.replace(/\.[^/.]+$/, "");

  return withoutExtension
    .replace(/_/g, " ")
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

function getItem(label, key, icon, children, type) {
  const structuredLabel = label.replace(/-/g, " ").replace(/\b\w/g, (char) => char.toUpperCase())
  return {
    key,
    icon,
    children,
    label: structuredLabel,
    type,
  }
}

const MediaManager = ({
  visible,
  onClose = () => {},
  mediaKeyType = "featured",
  isMultiple = false,
  onSelectItems,
  disableButton,
}) => {
  const [open, setOpen] = useState(visible ?? false)
  if (visible && visible !== open) {
    setOpen(visible)
  }
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [fileList, setFileList] = useState([])
  const [keyType, setKeyType] = useState(slugify(mediaKeyType))

  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItems, setSelectedItems] = useState([])

  const [modalVisible, setModalVisible] = useState(false)
  const [showFileContainer, setShowFileContainer] = useState(true)

  const [search, setSearch] = useState("")
  const [limit, setLimit] = useState(25)
  const [start, setStart] = useState(0)
  const [current, setCurrent] = useState(1)

  const [onDelete, { isLoading: deleting }] = useBulkDeleteMediaMutation("deleteEntityById")

  const [addMedia, { isLoading: adding }] = useAddMediaMutation("addMedia")

  const [updateEntityInfo, { isLoading: updating }] = useEditMediaMutation("updateMedia")

  const { data: mediaDirectories, refetch: refetchMediaDirectories } =
    useFetchMediaDirectoryListQuery("mediaDirectories")

  const {
    data: mediaListByDirectory,
    isLoading: mediaByDirectoryLoading,
    refetch: refetchMedia,
    isFetching,
    isError,
    error,
  } = useGetMediaByDirectoryQuery({
    directoryName: keyType,
    search,
    limit,
    start,
  })
  let total = mediaListByDirectory?.total
  let loading = deleting || adding || updating || isFetching || mediaByDirectoryLoading

  if (isError) {
    message.error("Error on loading media list by directory")
  }

  const items = mediaDirectories?.map((item, index) => {
    return getItem(item, item, <FolderOpenOutlined key={index} />)
  })

  let slugifyMediaKeyType = slugify(mediaKeyType)
  if (mediaDirectories && !mediaDirectories?.includes(slugifyMediaKeyType)) {
    items.push(
      getItem(
        slugifyMediaKeyType,
        slugifyMediaKeyType,
        <FolderOpenOutlined key={slugifyMediaKeyType} />
      )
    )
  }

  useEffect(() => {
    if (mediaListByDirectory) {
      setFileList(mediaListByDirectory.data)
    }
  }, [mediaListByDirectory])

  const handleOk = () => {
    setConfirmLoading(true)
    setTimeout(() => {
      setOpen(false)
      onClose()
      setConfirmLoading(false)
    }, 2000)
  }
  const handleCancel = () => {
    setOpen(false)
    onClose()
  }

  const onMenuClick = async (e) => {
    setKeyType(slugify(e.key))
    setShowFileContainer(true)
    setSelectedItems([])
    setStart(0)
    setCurrent(1)
  }

  const uploadImage = async (event) => {
    const selectedFiles = Array.from(event.target.files)
    const filesData = selectedFiles.map((file) => ({
      file,
      // caption: file.name,
      description: unslugify(file.name),
      alt_text: unslugify(file.name),
      directory: keyType,
    }))

    await addMedia(filesData)
      .unwrap()
      .then(() => {
        message.success("File(s) uploaded successfully")
        refetchMedia()
        if (!mediaDirectories.includes(keyType)) {
          refetchMediaDirectories()
        }
        setShowFileContainer(true)
      })
      .catch((error) => {
        message.error("Failed to upload file")
      })
  }

  const handleEdit = (item) => {
    setSelectedItem(item)
    setModalVisible(true)
  }

  const handleDelete = (ids) => {
    onDelete(ids)
      .unwrap()
      .then((data) => {
        refetchMedia()
        setFileList((prev) => prev.filter((itm) => !ids.includes(itm.id)))
        setSelectedItems([])
        if (!data.data?.some((item) => item.directory === keyType)) {
          refetchMediaDirectories()
        }
      })
      .catch(() => {
        message.error("Failed to delete file")
      })
  }

  const handleUpdate = async (id, values) => {
    await updateEntityInfo({
      formData: {
        ...values,
      },
      id,
    })
      .unwrap()
      .then((result) => {
        refetchMedia()
        setModalVisible(false)
        return result
      })
      .catch((error) => {
        throw error
      })
  }

  const handleCardClick = (item) => {
    if (isMultiple) {
      setSelectedItems((prevItems) =>
        prevItems.some((prevItem) => prevItem.id === item.id)
          ? prevItems.filter((prevItem) => prevItem.id !== item.id)
          : [...prevItems, item]
      )
    } else {
      setSelectedItems((prevItems) =>
        prevItems.some((prevItem) => prevItem.id === item.id) ? [] : [item]
      )
    }
  }

  const handleSelectItems = () => {
    onSelectItems && onSelectItems(selectedItems)
    setOpen(false)
    onClose()
  }

  const handleClearSelection = () => {
    setSelectedItems([])
  }

  const onPageChange = (page, pageSize) => {
    setCurrent(page)
    const newStart = (page - 1) * pageSize
    setLimit(pageSize)
    setStart(newStart)
  }

  const onSearch = (value) => {
    setSearch(value)
  }
  const onSearchChange = (e) => {
    let value = e.target.value
    if (value === "") {
      setSearch("")
    }
  }

  return (
    <>
      {!disableButton && (
        <Button type="primary" onClick={() => setOpen(true)}>
          Add Media
        </Button>
      )}
      <Modal
        //title="Media Manager"
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        width="100%"
        wrapClassName="flamingo-file-manager"
      >
        <div className="flamingo-file-manager-body">
          <div className="manager-toolbar">
            <button
              type="button"
              className="btn btn-link btn-upload"
              onClick={() => setShowFileContainer(false)}
            >
              <i className="icon">
                <CloudUploadOutlined style={{ fontSize: "20px" }} />
              </i>
              Upload Files
            </button>
            {showFileContainer && (
              <Search
                placeholder="Search media"
                allowClear
                onSearch={onSearch}
                className="input-media-search"
              />
            )}
            {showFileContainer && selectedItems && selectedItems.length ? (
              <div className="manager-toolbar-overlay">
                <ul>
                  <li>
                    <Popconfirm
                      placement="bottomLeft"
                      title={` Are you sure you want to delete ${selectedItems.length} items ?`}
                      onConfirm={() => handleDelete(selectedItems.map((item) => item.id))}
                    >
                      <button type="button" className="btn btn-danger">
                        <i className="bi-trash"></i> Delete
                      </button>
                    </Popconfirm>
                  </li>
                  <li>
                    {selectedItems && selectedItems.length ? (
                      <button type="button" className="btn btn-primary" onClick={handleSelectItems}>
                        <i className="bi-check"></i> Insert ({selectedItems.length})
                      </button>
                    ) : (
                      ""
                    )}
                  </li>
                  <li>
                    {selectedItems && selectedItems.length ? (
                      <button
                        type="button"
                        className="btn btn-warning"
                        onClick={handleClearSelection}
                      >
                        <i className="bi-x"></i> Clear Selection ({selectedItems.length})
                      </button>
                    ) : (
                      ""
                    )}
                  </li>
                </ul>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flamingo-file-manager-container">
            <Menu
              onClick={onMenuClick}
              style={{
                width: 256,
              }}
              defaultSelectedKeys={[keyType]}
              defaultOpenKeys={["sub1"]}
              mode="inline"
              items={items}
            />

            {showFileContainer ? (
              <div className="media-card-view" style={{ height: "500px", overflow: "auto" }}>
                {loading && <Spin tip="Loading..." className="loading" />}
                <ul>
                  {!loading && fileList && fileList.length > 0 ? (
                    fileList.map((itm, idx) => (
                      <li key={idx} onClick={() => handleCardClick(itm)} className="item-container">
                        <div
                          className={`item ${
                            selectedItems?.filter((a) => a.id === itm.id).length
                              ? "is-selected"
                              : ""
                          }`}
                        >
                          <div className="inner-wrap">
                            <span className="icon">
                              <img src={BASE_URL + itm.full_path} alt="" />
                            </span>
                            <span className="name">{itm.name}</span>
                          </div>
                          <div className="buttons">
                            <button className="edit-button" onClick={() => handleEdit(itm)}>
                              <i className="bi-pencil-square"></i>
                            </button>
                            <Popconfirm
                              placement="top"
                              title={` Are you sure you want to delete it?`}
                              onConfirm={() => handleDelete([itm.id])}
                            >
                              <button className="delete-button">
                                <i className="bi-trash"></i>
                              </button>
                            </Popconfirm>
                          </div>
                        </div>
                      </li>
                    ))
                  ) : (
                    <Empty />
                  )}
                </ul>

                <div className="clearfix"></div>
                <div style={{ position: "sticky", bottom: 0 }}>
                  <Pagination
                    current={current}
                    onChange={onPageChange}
                    total={total}
                    defaultPageSize={25}
                  />
                </div>

                <Modal
                  title="Edit Item"
                  open={modalVisible}
                  onCancel={() => setModalVisible(false)}
                  footer={null}
                >
                  <Spin spinning={loading} delay={500}>
                    <MediaUpdateForm item={selectedItem} handleSubmit={handleUpdate} />
                  </Spin>
                </Modal>
              </div>
            ) : (
              <div className="media-card-view">
                <Spin spinning={adding}>
                  <input type="file" accept="image/*" multiple onChange={uploadImage} />
                </Spin>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default MediaManager
