import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import CategoryTypeForm from "../../Components/Forms/CategoryTypeForm";
import {
  useCreateCategoryTypeMutation,
  useUpdateCategoryTypeMutation,
  useGetCategoryTypeByIdQuery,
} from "../../redux/slice/category/categoryTypeApiSlice";

function CategoryTypeAdd() {
  const [
    createEntity,
    {
      isLoading: creatingCategoryType,
      isError: createCategoryTypeError,
      error,
    },
  ] = useCreateCategoryTypeMutation();

  const [updateEntityInfo, { isLoading: updatingCategorytype }] =
    useUpdateCategoryTypeMutation();

  let data = {
    data: undefined,
    loading: creatingCategoryType,
    error: createCategoryTypeError,
    dataById: undefined,
  };

  const selectedForm = {
    data: undefined,
    loading: creatingCategoryType,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardAdd>
      <CategoryTypeForm
        isAddForm
        {...{
          getEntityById: useGetCategoryTypeByIdQuery,
          createEntity,
          updateEntityInfo,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default CategoryTypeAdd;
