import { useState, useEffect } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteMenuByIdMutation,
  useGetMenuListQuery,
  useSortMenuByIdMutation,
} from "../../redux/slice/menu/menuApiSlice";
import { useGetMenuTypeListQuery } from "../../redux/slice/menu/menuTypeApiSlice";

function List() {
  const [filter_options, setFilterOptions] = useState();
  const {
    data: menuTypelist,
    isLoading: menuTypeListLoading,
    isError: menuTypeListError,
    isSuccess: isSuccessMenuType,
  } = useGetMenuTypeListQuery();

  const [onDelete, { isLoading: deleteLoading, isSuccess }] =
    useDeleteMenuByIdMutation("deleteEntityById");
  const [onSort, { isLoading: sorting }] =
    useSortMenuByIdMutation("sortItemsById");

  useEffect(() => {
    if (menuTypelist) {
      setFilterOptions(
        menuTypelist?.map((e) => {
          return { value: e.id, text: e.type_title };
        })
      );
    }
  }, [menuTypelist]);

  let loading = menuTypeListLoading || deleteLoading || sorting;

  const tableConfig = {
    title: "Menu",
    filterOptionTitle: "Menu Type",
    filterOptions: filter_options,
    applyFilter: true,
    is_menu: true,
    tableColumns: [
      { name: "Title", selector: "item_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "operation", selector: "id", width: "5%" },
      { name: "Modified Date", selector: "updated_at", width: "10%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "item_title",
    addPath: "/menu/add",
    updatePath: "/menu/update",
    addTypePath: "/menu-type/add",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "item_title",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    sortItemsByIdList: (idList) => {
      onSort(idList);
    },
    loading,
  };

  return (
    <DashboardList getList={useGetMenuListQuery()} tableConfig={tableConfig} />
  );
}

export default List;
