import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import SettingForm from "../../Components/Forms/SettingForm";
import { useCreateSettingMutation } from "../../redux/slice/settings/settingsApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdSettingData,
      isLoading: creatingSetting,
      isError: createSettingError,
      isSuccess: createSettingSuccess,
      error,
    },
  ] = useCreateSettingMutation("createSetting");

  let data = {
    data: undefined,
    loading: creatingSetting,
    error: createSettingError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingSetting,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, createSettingSuccess, "Setting");

  return (
    <DashboardAdd>
      <SettingForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
