
//import { selectAllLogs, useGetListQuery } from "./../redux/slice/logsSlice";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Spin, Card } from "antd";
import { useGetLogsListQuery } from "../redux/slice/logs/logApiSlice";
//import { useSelector } from "react-redux";

export default function RecentUpdates({ limit, dashboard }) {
  const {
    data,
    isLoading,
    isError,
    isSuccess,
  } = useGetLogsListQuery();

  return (
    <Card className="card bg-white relative">
      {isLoading && <div className="loader-overly">
        <Spin tip="Loading..." />
      </div>}
      <div className="card-header">
        <div className="card-title">
          <h2>Recent Update</h2>
          <p>{data?.length} Actual Logs</p>
        </div>
        {dashboard && (
          <Link
            to="/all-logs"
            className="btn btn-link font-weight-medium text-body"
          >
            View All Logs <i className="bi bi-chevron-double-right"></i>
          </Link>
        )}
      </div>
      <div className="card-body">
        <div className="timeline">
          <ul>
            {isSuccess && data?.slice(0, limit)?.map((value, idx) => {
              return (
                <li className={value.action_category_key} key={idx}>
                  <div className="time">
                    {" "}
                    <Moment fromNow ago>
                      {value.created_at}
                    </Moment>
                  </div>
                  <div className="intro-img"></div>
                  <div className="intro-text">
                    <div className="col">
                      <h4>{value?.action_title}</h4>
                      <ul className="meta">
                        <li>{value?.logger?.name}</li>
                        <li>{value?.action_category}</li>
                      </ul>
                    </div>
                    <div className="col">
                      <span
                        className={`badge rounded-pill bg-light-${(value.primary_action === "soft-delete" &&
                          "danger") ||
                          (value.primary_action === "create" && "success") ||
                          (value.primary_action === "update" && "primary")
                          }`}
                      >
                        {value.primary_action}
                      </span>
                    </div>
                    <div className="col">
                      <a href="#" className="btn btn-primary light">
                        <i className="bi bi-chevron-right"></i>
                      </a>
                    </div>
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </Card>
  )



}
