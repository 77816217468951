import { useEffect } from "react"
import { message } from "antd"

const useHandleUpdateMessage = (error, updateSuccess, title) => {
  useEffect(() => {
    // Error popup message
    error?.data && message.error(`Error updating ${title}`)

    // Updated popup message
    updateSuccess && message.success(`${title} updated successfully!`)
  }, [error, updateSuccess, title])
}

export default useHandleUpdateMessage
