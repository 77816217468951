import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Polyline } from 'react-leaflet';
import L from 'leaflet';
import MarkerIcon from "../../assets/images/marker.png";
import polyline from 'polyline-encoded';
import 'leaflet/dist/leaflet.css';


const CustomMap = ({ updateCoordinate, generateRouteInfo, data, mapPosition, zoom, chosenItem }) => {
  const mapRef = useRef(null);
  const [mapIsReady, setMapIsReady] = useState(false);

  useEffect(() => {
    if (mapIsReady, mapRef.current && data?.origin_coordinate && data?.destination_coordinate) {
      const bounds = L.latLngBounds([
        [data.origin_coordinate[0], data.origin_coordinate[1]],
        [data.destination_coordinate[0], data.destination_coordinate[1]],
      ]);
      mapRef.current.fitBounds(bounds);
      generateRouteInfo()
    } else if (mapRef.current && (data?.origin_coordinate || data?.destination_coordinate)) {
      const coordinates = data.origin_coordinate || data.destination_coordinate
      const bounds = L.latLngBounds([
        [coordinates[0], coordinates[1]],
        [coordinates[0], coordinates[1]],
      ]);
      mapRef.current.fitBounds(bounds);
      generateRouteInfo()
    }
  }, [data?.origin_coordinate, data?.destination_coordinate, chosenItem.day, chosenItem.stop, mapIsReady]);

  const getValidCoordinate = (coordinate) => {
    if (coordinate && coordinate.length === 2) {
      return [parseFloat(coordinate[0]), parseFloat(coordinate[1])];
    }
    return null;
  };

  const originCoordinate = getValidCoordinate(data?.origin_coordinate);
  const destinationCoordinate = getValidCoordinate(data?.destination_coordinate);

  const center = originCoordinate || destinationCoordinate || [0, 0];

  const handleMarkerDragEnd = (event, key) => {
    updateCoordinate([event.target.getLatLng().lat, event.target.getLatLng().lng], key)
  };
  const polylineCoordinates = (data?.encoded_polyline) ? polyline.decode(data.encoded_polyline) : []

  const render_manual_option = (data?.origin && !data?.origin_coordinate) || (data?.destination && !data.destination_coordinate)
  const handleMapClick = (e) => {
    mapRef?.current?.off("click", handleMapClick);
    if (render_manual_option) {
      const key = data.origin_coordinate ? "destination_coordinate" : "origin_coordinate"
      updateCoordinate([e.latlng.lat, e.latlng.lng], key)
    }
  }
  mapRef?.current?.on("click", handleMapClick);

  return (
    <MapContainer
      center={((mapRef?.current?._lastCenter) ? (mapRef?.current?._lastCenter) : mapPosition) || center}
      zoom={mapRef?.current?._zoom ? mapRef.current._zoom : zoom}
      style={{ height: '500px', width: '100%' }}
      ref={mapRef}
      whenReady={()=>setMapIsReady(true)}
    >
      {render_manual_option && <div style={{backgroundColor: 'white', width: 200, position: 'absolute', zIndex: 1000, top: 10, left: 60, border: "1px solid #aaa", boxShadow: '0px 0px 1px black', padding: 5}}>
        <b>Location of "{data[data.origin && !data.origin_coordinate ? 'origin' : 'destination']}"</b><br/>
        <div>Click on the map to set location manually</div>
      </div>}
      {data?.origin && data?.destination && data.origin_elevation && data.destination_elevation &&
        <div style={{position:'absolute', zIndex: 1000, bottom: 10, right: 10, padding: 5, border: "1px solid #aaa", boxShadow: '0px 0px 1px black', backgroundColor: 'white' }}>
          <div><b>Elevation</b></div>
          <div>{data.origin}: {data.origin_elevation} m</div>
          <div>{data.destination}: {data.destination_elevation} m</div>
        </div>
      }
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />

      <Polyline positions={polylineCoordinates} color="green" weight={6} />

      {originCoordinate && (
        <Marker
          draggable={true}
          position={originCoordinate}
          icon={L.icon({ iconUrl: MarkerIcon, iconSize: [40, 40] })}
          eventHandlers={{
            dragend: (e) => {
              if (data.origin_coordinate && data.destination_coordinate) {
                generateRouteInfo()
              }
              handleMarkerDragEnd(e, 'origin_coordinate')
            }
          }}
        >
          <Popup>
            Origin: {data.origin}<br/>
            Elevation: {data.origin_elevation} m
          </Popup>
        </Marker>
      )}

      {destinationCoordinate && (
        <Marker
          draggable={true}
          position={destinationCoordinate}
          icon={L.icon({ iconUrl: MarkerIcon, iconSize: [40, 40] })}
          eventHandlers={{
            dragend: (e) => {
              if (data.origin_coordinate && data.destination_coordinate) {
                generateRouteInfo()
              }
              handleMarkerDragEnd(e, 'destination_coordinate')
            }
          }}
        >
          <Popup>
            Destination: {data.destination} <br />
            Elevation: {data.destination_elevation} m
          </Popup>
        </Marker>
      )}

    </MapContainer>
  );
};

export default CustomMap;
