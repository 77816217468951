import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import MediaForm from "../../Components/Forms/MediaForm";
import { useCreateMediaMutation } from "../../redux/slice/media/mediaApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdMediaData,
      isLoading: creatingMedia,
      isError: createMediaError,
      isSuccess: createMediaSuccess,
      error,
    },
  ] = useCreateMediaMutation("createMedia");

  let data = {
    data: undefined,
    loading: creatingMedia,
    error: createMediaError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingMedia,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, createMediaSuccess, "Media");

  return (
    <DashboardAdd>
      <MediaForm
        isAddForm
        {...{
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default Add;
