import { apiSlice } from "../../api/apiSlice";
import {
  createDeleteRestoreMutation,
  createQueryById,
  createQueryForList,
} from "../commonFunction";

export const menuTypeApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["MenuType", "MenuTypeById", "DetetedMenuType"],
  endpoints: (builder) => ({
    getMenuTypeList: createQueryForList(builder, "/menu/type/active", [
      "MenuType",
    ]),

    getDeletedMenuTypeList: createQueryForList(builder, "/menu/type/deleted", [
      "DetetedMenuType",
    ]),

    getMenuTypeById: createQueryById(builder, "/menu/type/active/", [
      "MenuTypeById",
    ]),

    deleteMenuTypeById: builder.mutation({
      query: (id) => ({
        url: "/menu/type/bulk/delete",
        method: "POST",
        body: { bulk: [id] },
      }),
      invalidatesTags: ["MenuType", "MenuTypeById"],
    }),

    permanentDeleteMenuType: createDeleteRestoreMutation(
      builder,
      "/menu/type/bulk/perma-delete",
      ["DetetedMenuType"]
    ),

    restoreDeletedMenuType: createDeleteRestoreMutation(
      builder,
      "/menu/type/bulk/restore",
      ["DetetedMenuType"]
    ),

    createMenuType: builder.mutation({
      query: (formData) => ({
        url: "/menu/type/add",
        method: "POST",
        body: formData,
      }),
      invalidateTags: ["MenuType", "MenuTypeById"],
    }),

    updateMenuType: builder.mutation({
      query: ({ formData, id }) => {
        const webKitFormData = new FormData();
        Object.keys(formData).forEach((item) => {
          webKitFormData.append(item, formData[item]);
        });
        webKitFormData.append("_method", "put");

        return {
          url: `/menu/type/edit/${id}`,
          method: "POST",
          headers: {
            Accept: "application/json",
          },
          body: { ...formData, _method: "put" },
        };
      },
      invalidateTags: ["MenuType", "MenuTypeById"],
    }),
  }),
});

export const {
  useGetMenuTypeListQuery,
  useGetMenuTypeByIdQuery,
  useGetDeletedMenuTypeListQuery,
  useDeleteMenuTypeByIdMutation,
  usePermanentDeleteMenuTypeMutation,
  useRestoreDeletedMenuTypeMutation,
  useCreateMenuTypeMutation,
  useUpdateMenuTypeMutation,
} = menuTypeApiSlice;
