import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import MenuForm from "../../Components/Forms/MenuForm";
import {
  useGetMenuByIdQuery,
  useCreateMenuMutation,
  useUpdateMenuMutation,
} from "../../redux/slice/menu/menuApiSlice";

function Update() {
  let params = useParams();
  const getEntityById = useGetMenuByIdQuery;

  const [
    createEntity,
    {
      data: createdMenuData,
      isLoading: creatingMenu,
      isError,
      error: createMenuError,
    },
  ] = useCreateMenuMutation("createMenu");

  const [
    updateMenu,
    { data: updatedMenu, isLoading: updating, isError: updateMenuError, error },
  ] = useUpdateMenuMutation("updateMenu");

  const {
    data: menus,
    isLoading: loadingById,
    isError: getEntityError,
    isSuccess: successById,
  } = getEntityById(params.id);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: getEntityError || updateMenuError,
    dataById: successById && menus,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingMenu || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <MenuForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateMenu,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
