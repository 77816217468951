import React from "react";
import { notification } from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "" };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by Error Boundary:", error, errorInfo);
    this.showNotification(error.message);
  }

  showNotification = (errorMessage) => {
    notification.config({
      placement: "top",
      top: 50,
    });
    notification.error({
      message: "An Error Occurred",
      description: errorMessage || "Contact IT support",
      duration: 5,
      onClose: this.handleClose,
    });
  };

  handleClose = () => {
    this.setState({ hasError: false, errorMessage: "" });
  };

  render() {
    return this.props.children;
  }
}

export default ErrorBoundary;
