import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import MenuTypeForm from "../../Components/Forms/MenuTypeForm";
import {
  useCreateMenuTypeMutation,
  useUpdateMenuTypeMutation,
  useGetMenuTypeByIdQuery,
} from "../../redux/slice/menu/menuTypeApiSlice";

function MenuTypeAdd() {
  const [
    createEntity,
    { isLoading: creatingMenuType, isError: createMenuTypeError, error },
  ] = useCreateMenuTypeMutation();

  const [updateEntityInfo, { isLoading: updatingMenutype }] =
    useUpdateMenuTypeMutation();

  let data = {
    data: undefined,
    loading: creatingMenuType,
    error: createMenuTypeError,
    dataById: undefined,
  };

  const selectedForm = {
    data: undefined,
    loading: creatingMenuType,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardAdd>
      <MenuTypeForm
        isAddForm
        {...{
          getEntityById: useGetMenuTypeByIdQuery,
          createEntity,
          updateEntityInfo,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

export default MenuTypeAdd;
