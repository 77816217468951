import { useEffect } from "react"
import { useParams } from "react-router-dom"

import DashboardUpdate from "../../Components/Layouts/DashboardUpdate"
import PackageForm from "../../Components/Forms/PackageForm"
import {
  useCreatePackageMutation,
  useGetPackageByIdQuery,
  useUpdatePackageInfoMutation,
} from "../../redux/slice/package/packageApiSlice"
import useHandleUpdateMessage from "../../hooks/useHandleUpdateMessage"

function Update() {
  let params = useParams()
  const getEntityById = useGetPackageByIdQuery

  const [
    createEntity,
    { data: createdPackageData, isLoading: creatingPackage, isError, error: createPackageError },
  ] = useCreatePackageMutation("createPackage")

  const [
    updatePackage,
    {
      data: updatedPackage,
      isLoading: updating,
      isError: updatePackageError,
      isSuccess: packageUpdated,
      error,
    },
  ] = useUpdatePackageInfoMutation("updatePackage")

  const {
    data: packages,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id)

  useEffect(() => {
    refetch()
  }, [])

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updatePackageError,
    dataById: successById && packages,
  }
  const selectedForm = {
    data: undefined,
    loading: creatingPackage || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  }

  useHandleUpdateMessage(error, packageUpdated, "Package")

  return (
    <DashboardUpdate>
      <PackageForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updatePackage,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  )
}

export default Update
