import React, { useState, useEffect } from "react";
import DashboardList from "../../Components/Layouts/DashboardList";
import { useGetCategoryListQuery } from "../../redux/slice/category/categoryApiSlice";
import { useDeleteFaqMutation, useGetFaqListQuery } from "../../redux/slice/faq/faqApiSlice";
function List() {
  const [filter_options, setFilterOptions] = useState();
  const { data: categoryList } = useGetCategoryListQuery();

  const [onDelete, { isLoading: deleteLoading, isSuccess }] =
    useDeleteFaqMutation("deleteEntityById");

  useEffect(() => {
    if (categoryList) {
      setFilterOptions(
        categoryList
          ?.filter((e) => e.type?.category_key === "faqs")
          .map((e) => {
            return { value: e.id, text: e.title };
          })
      );
    }
  }, [categoryList]);

  const tableConfig = {
    title: "Faqs",
    filterOptionTitle: "Category",
    filterOptions: filter_options,
    applyFilter: true,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      { name: "Title", selector: "question", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: `faq_cats`,
    searchByColumn: "question",
    updatePath: "/faq/update",
    addPath: "/faq/add",
    noDataMessage: "No Faqs exists",
    updateLinkColumn: "question",
    deleteBtnText: "Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
  };

  return <DashboardList getList={useGetFaqListQuery()} tableConfig={tableConfig} />;
}
export default List;
