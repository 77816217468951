import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useDeleteTripInfoByIdMutation,
  useGetTripInfoListQuery,
} from "../../redux/slice/tripInfo/tripInfoApiSlice";

function List() {
  const [onDelete, { isLoading: deleteLoading, isSuccess }] =
    useDeleteTripInfoByIdMutation("deleteEntityById");
  const tableConfig = {
    title: "Trip Note",
    filterOptionTitle: "Category Type",
    //filterOptions: filter_options,
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "page_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "page_title",
    addPath: "/tripinfo/add",
    updatePath: "/tripinfo/update",
    noDataMessage: "No trip note exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "page_title",
    deleteItemsByIdList: (idList) => {
      console.log(idList);
      onDelete(idList);
    },
  };
  return (
    <DashboardList
      getList={useGetTripInfoListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default List;
