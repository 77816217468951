import DashboardList from "../../Components/Layouts/DashboardList";

import {
  useGetDeletedTripInfoListQuery,
  usePermanentDeleteTripInfoMutation,
  useRestoreDeletedTripInfoMutation,
} from "../../redux/slice/tripInfo/tripInfoApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteTripInfoMutation();
  const [onRestore, { isLoading: restoring }] =
    useRestoreDeletedTripInfoMutation();

  let loading = permaDeleting || restoring;

  const tableConfig = {
    title: "Deleted Trip info(s)",
    applyFilter: false,
    tableColumns: [
      { name: "Title", selector: "page_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Modified Date", selector: "updated_at", width: "15%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "page_title",
    //updatePath: "/category/update",
    addPath: "/tripinfo/add",
    restorePath: "/tripinfos",
    noDataMessage: "No trip info exists",
    //updateLinkColumn: 'title',
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedTripInfoListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
