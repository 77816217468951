import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import SettingForm from "../../Components/Forms/SettingForm";
import {
  useCreateSettingMutation,
  useGetSettingByIdQuery,
  useUpdateSettingMutation,
} from "../../redux/slice/settings/settingsApiSlice";
import { useEffect } from "react";

function Update() {
  let params = useParams();
  const getEntityById = useGetSettingByIdQuery;

  const [
    createEntity,
    {
      data: createdSettingData,
      isLoading: creatingSetting,
      isError,
      error: createSettingError,
    },
  ] = useCreateSettingMutation("createSetting");

  const [
    updateSetting,
    { isLoading: updating, isError: updateSettingError, error },
  ] = useUpdateSettingMutation();

  const {
    data: settings,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = getEntityById(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateSettingError,
    dataById: successById && settings,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingSetting || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  return (
    <DashboardUpdate>
      <SettingForm
        isUpdateForm
        {...{
          params,
          getEntityById,
          createEntity,
          updateEntityInfo: updateSetting,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
// const mapStateToProps = (state) => {
//   return {
//     data: state.settings,
//     loginData: state.loginData,
//     selectedForm: state.settingForm,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
//     createEntity: (formData, tokenData) =>
//       dispatch(createEntity(formData, tokenData)),
//     updateEntityInfo: (formData, tokenData, id) =>
//       dispatch(updateEntityInfo(formData, tokenData, id)),
//     setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps)(Update);
