import { apiSlice } from "../../api/apiSlice";
import {
  createQueryForList,
} from "../commonFunction";

export const logApiSlice = apiSlice.injectEndpoints({
  tagTypes: ["Logs", "LogsById"],
  endpoints: (builder) => ({
    getLogsList: createQueryForList(builder, "/logs/all", [
      "Logs",
    ]),

  }),
});

export const {
  useGetLogsListQuery,
} = logApiSlice;
