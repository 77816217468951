import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import UserForm from "../../Components/Forms/UserForm";
import { useAddNewUserMutation } from "../../redux/slice/userSlice";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  let [
    createEntity,
    {
      isLoading: creating,
      isError: createError,
      error: createEntityError,
      isSuccess: userCreated,
    },
  ] = useAddNewUserMutation();

  let data = {
    data: undefined,
    loading: creating,
    error: createError,
    dataById: undefined,
  };

  useHandleCreateMessage(createEntityError, userCreated, "User");

  return (
    <DashboardAdd>
      <UserForm isAddForm {...{ createEntity, data }} />
    </DashboardAdd>
  );
}

export default Add;
