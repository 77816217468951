import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import AuthorForm from "../../Components/Forms/AuthorForm";

import {
  useCreateAuthorMutation,
  useGetAuthorByIdQuery,
} from "../../redux/slice/author/authorApiSlice";
import { useEffect } from "react";
import { message } from "antd";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";

function Add() {
  const [
    createEntity,
    {
      data: createdAuthorData,
      isLoading: creatingAuthor,
      isError: createAuthorError,
      isSuccess: authorCreated,
      error,
    },
  ] = useCreateAuthorMutation("createAuthor");

  let data = {
    data: undefined,
    loading: creatingAuthor,
    error: createAuthorError,
    dataById: undefined,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingAuthor,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  useHandleCreateMessage(error, authorCreated, "Author");

  return (
    <DashboardAdd>
      <AuthorForm
        isAddForm
        {...{
          getEntityById: useGetAuthorByIdQuery,
          createEntity,
          data,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}
export default Add;
