import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import RedirectionForm from "../../Components/Forms/RedirectionForm";

import {
  useGetRedirectionListQuery,
  useUpdateRedirectionInfoMutation,
} from "../../redux/slice/redirection/redirectionApiSlice";

function Redirection() {
  const {
    data: list,
    isLoading: listLoading,
    isError: isListError,
    error: listError,
  } = useGetRedirectionListQuery("redirectList");
  const [
    updateEntityInfo,
    { isLoading: updating, isError: isUpdateError, error: updateError },
  ] = useUpdateRedirectionInfoMutation("updateRedirection");

  let data = {
    data: list ? list : [],
    loading: updating || listLoading,
  };
  return (
    <DashboardUpdate>
      <RedirectionForm
        isUpdateForm
        {...{
          updateEntityInfo,
          data,
        }}
      />
    </DashboardUpdate>
  );
}

export default Redirection;
