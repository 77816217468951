import { useEffect } from "react";
import { useParams } from "react-router-dom";
import DashboardUpdate from "../../Components/Layouts/DashboardUpdate";
import TeamForm from "../../Components/Forms/TeamForm";
import {
  useCreateTeamMutation,
  useGetTeamByIdQuery,
  useUpdateTeamInfoMutation,
} from "../../redux/slice/team/teamApiSlice";

function Update() {
  let params = useParams();

  const [
    createEntity,
    {
      data: createdTeamData,
      isLoading: creatingTeam,
      isError,
      error: createTeamError,
    },
  ] = useCreateTeamMutation("createTeam");

  const [
    updateTeam,
    { data: updatedTeam, isLoading: updating, isError: updateTeamError, error },
  ] = useUpdateTeamInfoMutation("updateTeam");

  const {
    data: team,
    isLoading: loadingById,
    isError: updateError,
    isSuccess: successById,
    refetch,
  } = useGetTeamByIdQuery(params.id);

  useEffect(() => {
    refetch();
  }, []);

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: updateError || updateTeamError,
    dataById: successById && team,
  };
  const selectedForm = {
    data: undefined,
    loading: creatingTeam || loadingById || updating,
    error: error?.data.errors,
    loadedRecently: false,
    dataById: undefined,
  };
  return (
    <DashboardUpdate>
      <TeamForm
        isUpdateForm
        {...{
          params,
          createEntity,
          updateEntityInfo: updateTeam,
          data,
          selectedForm,
        }}
      />
    </DashboardUpdate>
  );
}

export default Update;
