import DashboardList from "../../Components/Layouts/DashboardList";
import {
  useGetDeletedFaqListQuery,
  usePermanentDeleteFaqMutation,
  useRestoreFaqListMutation,
} from "../../redux/slice/faq/faqApiSlice";

function DeletedList() {
  const [onDelete, { isLoading: permaDeleting }] =
    usePermanentDeleteFaqMutation();
  const [onRestore, { isLoading: restoring }] = useRestoreFaqListMutation();

  let loading = permaDeleting || restoring;
  const tableConfig = {
    title: "Deleted FAQ(s)",
    filterOptionTitle: "Author Type",
    filterOptions: ["admin", "superadmin", "member"],
    defaultFilterValue: "admin",
    applyFilter: false,
    tableColumns: [
      { name: "Status", selector: "status", width: "3%" },
      { name: "Title", selector: "question", width: "70%" },
      { name: "Modified Date", selector: "updated_at", width: "17%" },
    ],
    filterByColumn: "faq_cats",
    searchByColumn: "question",
    updatePath: "/faq/update",
    addPath: "/faq/add",
    restorePath: "/blog/authors",
    noDataMessage: "No Faqs exists",
    updateLinkColumn: "question",
    deleteBtnText: "Permanent Delete",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    restoreItemsByIdList: (idList) => {
      onRestore(idList);
    },
    loading,
  };
  return (
    <DashboardList
      getList={useGetDeletedFaqListQuery()}
      tableConfig={tableConfig}
    />
  );
}

export default DeletedList;
